import React,{useEffect} from "react"
import { Button, Image, Stack, Text } from "@chakra-ui/react";
import PaddingContainer from "../components/PaddingContainer";
import how1 from "../assets/how-1.png";
import how2 from "../assets/how-2.png";
import { Link } from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'

function HowItWorks() {
  useEffect(() => {
    AOS.init({duration: 2000})
}, [])

  return (
    <PaddingContainer
     bg="#F3F4F5" 
     id="how-it-works"
     data-aos='zoom-in'>
      <Stack textAlign={"center"} alignItems="center" mb="80px !important">
        <Stack position={"relative"}>
          <Text
            position={"absolute"}
            fontFamily={"Montserrat"}
            fontWeight="800"
            color="#3a49d11f"
            left="120px"
            top="-15px"
            fontSize="2em"
            whiteSpace={"nowrap"}
          >
            App Usage
          </Text>
          <Text
            fontFamily="Montserrat"
            fontWeight="800"
            fontSize={"2em"}
            color="#1A0031"
          >
            {" "}
            <span style={{ color: "#42A293", fontWeight: "bolder" }}>
              &mdash;
            </span>{" "}
            How It Works
          </Text>
        </Stack>

        <Text
          maxW={["400px", "400px", "580px"]}
          textAlign={"center"}
          color="#434242"
          lineHeight={"1.7"}
          fontFamily="Trenda-regular"
        >
          We understand that borrowers and lenders alike have unique needs and
          goals, and we strive to meet those needs with our comprehensive
          peer-to-peer lending services.
        </Text>
      </Stack>

      <Stack spacing={"150px"}>


        <Stack
          direction={["column", "column", "row"]}
          alignItems={["center", "center", "flex-start"]}
          justifyContent={["center", "center", "space-between"]}
          spacing={["20px", "20px", "30px", "30px"]}
        >
          <Stack flex="1" maxW="400px">
            <Text fontFamily="Montserrat" fontWeight="600">
              {" "}
              <span style={{ color: "#42A293", fontWeight: "bolder" }}>
                &mdash;&mdash;{" "}
              </span>
              Borrowers
            </Text>
            <Text
              fontFamily="Montserrat"
              fontWeight="400"
              color="#212121"
              fontSize={["1.5em", "1.5em", "1.7em", "1.7em"]}
            >
              Get access to funds to <br />
              <span style={{ fontWeight: "800", color: "#180656" }}>
                simplify your life, with relevant{" "}
              </span>
              requirements
            </Text>
            <Text
              fontFamily="Trenda-regular"
              color="#434242"
              my={["10px", "10px", "20px", "20px"]}
              lineHeight={"1.7"}
            >
              A lender will credit your wallet and once approved, funds are
              typically received in your WhoUEpp wallet within 5 minutes.
              <br /> At WhoUEpp, you too can invest in tiny & affordable chunks
              and still enjoy the same return rates in amazing ways.
            </Text>
            <Link to="borrowers-page">
              <Button
                _hover={{ background: "#3A49D1" }}
                fontFamily={"Trenda-regular"}
                p={["5px 20px", "5px 20px", "10px 30px", "10px 30px"]}
                fontWeight={"light"}
                alignSelf={"flex-start"}
                bg="#3A49D1"
                color="#fff"
              >
                Learn More
              </Button>
            </Link>
          </Stack>
          <Image
            w={["400px", "400px", "400px", "600px"]}
            flex="1"
            src={how1}
            alt="happy people"
          />
        </Stack>



        <Stack
          direction={{ base: "column", md: "row", lg: "row" }}
          alignItems={{ base: "center", md: "center", lg: "flex-start" }}
          justifyContent={"space-between"}
          spacing={[ null, "30px", "30px"]}
        >
          <Image
            loading="lazy"
            w={{ base: "200px", md: "200px", lg: "200px", xl: "500px" }}
            flex="1"
            src={how2}
            alt="happy people"
            display={{ base: "none", md: "block", lg: "block" }}
          />
          <Stack flex="1" maxW={{ base: null, md: null, lg: "300px", xl: "400px" }}>
            <Text fontFamily="Montserrat" fontWeight="600">
              {" "}
              <span style={{ color: "#42A293", fontWeight: "bolder" }}>
                &mdash;&mdash;{" "}
              </span>
              Lenders
            </Text>
            <Text
              fontFamily="Montserrat"
              fontWeight="400"
              color="#212121"
              fontSize={{ base: "1.5em", lg: "1.7em" }}
            >
              Invest in someone{" "}
              <span style={{ fontWeight: "800", color: "#180656" }}>
                else's aspirations while you move closer to financial independence.
              </span>
            </Text>
            <Text
              fontFamily="Trenda-regular"
              color="#434242"
              my={{ base: "10px !important", lg: "20px !important" }}
              lineHeight={{ base: "1.5", lg: "1.7" }}
            >
              Whether you're a beginner or an experienced lender, let your money work
              for you with our smart lending solutions. You can start lending to
              thousands of borrowers, take advantage of an appealing interest rate, and
              manage your own risks.
            </Text>
            <Link to="lenders-page">
              <Button
                _hover={{ background: "#3A49D1" }}
                fontFamily={"Trenda-regular"}
                p={{ base: "8px 20px", lg: "10px 30px" }}
                fontWeight={"light"}
                alignSelf={"flex-start"}
                bg="#3A49D1"
                color="#fff"
              >
                Learn More
              </Button>
            </Link>
          </Stack>
        </Stack>

      </Stack>
    </PaddingContainer>
  );
}

export default HowItWorks;
