import {
    Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

export const ContactUsModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} isCentered>
      <ModalOverlay />
      <ModalContent p={"40px"}>
        <Box
          borderTop={"3px solid #42A293"}
          w={"30px"}
          rounded={"lg"}
          position={"absolute"}
          left={"7%"}
          top={"80px"}
        ></Box>
        <ModalHeader
          color={"#1A0031"}
          fontWeight={"bold"}
          fontSize={"2.2rem"}
          pb={"50px"}
          pl={"60px"}
        >
          Contact Us
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w={"100%"} justify={"space-between"}>
            <Box w={"45%"}>
              <Text
                color={"#212121"}
                fontSize={"1.3rem"}
                fontWeight={"semibold"}
              >
                Corporate Office
              </Text>
              <Text color={"#707070"} fontSize={"1rem"} pt={"10px"}>
                17A Ozumba Mbadiwe Ave, Victoria Island, Lagos
              </Text>
              <Text
                color={"#212121"}
                fontSize={"1.3rem"}
                fontWeight={"semibold"}
                pt={"25px"}
              >
                Telephone Number
              </Text>
              <Text color={"#707070"} fontSize={"1rem"} pt={"10px"}>
                +2348025011147
              </Text>
              <Text
                color={"#212121"}
                fontSize={"1.3rem"}
                fontWeight={"semibold"}
                pt={"25px"}
              >
                Email Address
              </Text>
              <Text color={"#707070"} fontSize={"1rem"} pt={"10px"}>
                support@whouepp.com
              </Text>
            </Box>

            <Box borderLeft={"2px solid #C7C7C7"} pl={"25px"} w={"55%"}>
              <Text
                fontSize={"1.6rem"}
                fontWeight={"bold"}
                color={"#212121"}
                pb={"15px"}
              >
                Send Message
              </Text>
              <Flex>
                <Box mr={"25px"}>
                  <Text mb="8px" color={"#434242"} fontSize={"14px"}>
                    Full Name{" "}
                  </Text>
                  <Input size="md" />
                </Box>
                <Box>
                  <Text mb="8px" color={"#434242"} fontSize={"14px"}>
                    Email Address{" "}
                  </Text>
                  <Input size="md" />
                </Box>
              </Flex>
              <Box>
                <Text mb="8px" color={"#434242"} fontSize={"14px"} pt={"10px"}>
                  Message{" "}
                </Text>
                <Textarea
                  color={"#707070"}
                  resize={"none"}
                  placeholder="What do you want to tell us?"
                  h={"120px"}
                />
              </Box>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button bg="#3A49D1" onClick={onClose} color={"#F8F8F8"} size={"lg"}>
            Secondary Action
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
