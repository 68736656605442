import React from "react";
import Hero from "../components/enterprise/Hero";
import HowItWorks from "../components/enterprise/HowItWorks";
import Pricing from "../components/enterprise/Pricing";
import { Faq } from "../components/Faq";
import SignUp from "../components/enterprise/SignUp";
import woman from "../assets/woman.png";


const LandingPage = () => {
  return (
    <>
      {/* <Nav /> */}
      <Hero />
      <HowItWorks />
      <Pricing />
      <Faq img={woman} />
      <SignUp />
      {/* <Footer /> */}
    </>
  );
};

export default LandingPage;
