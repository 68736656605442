import {
  Box,
  Flex,
  Image,
  Input,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo.png";
import { ContactUsModal } from "./ContactUsModal";

const Footer = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [showMenu, setShowMenu] = useState(null);
  const menuOpen = useBreakpointValue([false, false, true, true]);

  useEffect(() => {
    setShowMenu(menuOpen);
  }, [menuOpen]);
  return (
    <>
      <Flex
        width="100%"
        flexDirection={["column", "column", "column", "row"]}
        bg="#CFE5E3"
        justify={"space-between"}
        align="center"
        px={["20px", "20px", "60px"]}
        py={["20px", "20px", "35px"]}
        borderTopRadius="30px"
        mt={["0px", "0px", "20px", "10px"]}
      >
        <Box width={["100%", "100%", "10%"]} align="center">
          <Image loading="lazy" src={logo} alt="logo" />
        </Box>
        <Box width={["100%", "100%", "100%", "60%"]}>
          <Flex
            // justify={"space-between"}
            width="100%"
            justify={["space-between", "space-between", "center"]}
            flexDirection={["column", "column", "row"]}
            color="#1A0031"
            align="center"
            fontSize={["16px", "16px", "16px"]}
            fontWeight={"semibold"}
            flexWrap="wrap"
          >
            <Link to="#feature">
              <Text mr={["0", "0", "15px"]}>Feature</Text>
            </Link>
            <Link to="#how-it-works">
              <Text mr={["0", "0", "15px"]}>How it works</Text>
            </Link>
            <Link to="/privacy-policy">
              <Text mr={["0", "0", "15px"]}>Privacy Policy</Text>
            </Link>
            <Link to="/terms-condition">
              <Text mr={["0", "0", "15px"]}>Terms and Condition</Text>
            </Link>
            <Link to="/faqs">
              <Text mr={["0", "0", "15px"]}>FAQs</Text>
            </Link>
            <Link to="#contact">
              <button style={{ fontWeight: "600" }} onClick={() => onOpen()}>
                Contact Us
              </button>
            </Link>
          </Flex>
          <Text
            mt="20px"
            fontWeight="semibold"
            fontSize="18px"
            textAlign="center"
            color="#5E6CE7"
          >
            The Whouepp App is a Product of Promena Services Limited.
          </Text>
        </Box>
        <VStack
          width={["100%", "100%", "100%", "20%"]}
          spacing={["0px", "0px", "15px", "25px"]}
          mt={["15px", "0", "0"]}
        >
          <Text color="#212121">Sign up for updates & newsletters</Text>
          <Input
            placeholder="Enter Email"
            color="#000"
            bg="#FCFBFB"
            borderRadius={"10px"}
            _placeholder={{ color: "gray" }}
          />
        </VStack>
      </Flex>
      <ContactUsModal {...{ isOpen, onClose }} />
    </>
  );
};

export default Footer;
