
export const  faqs = [
    {
    question: "What is peer-to-peer lending?",
    answer: "Peer-to-peer (P2P) lending enables individuals to obtain loans directly from other individuals, cutting out the financial institution as the middleman.",
    },

    {
    question:"What is the whoUepp app?",
    answer:"whoUepp is an app that uses the peer-to-peer mechanism, creating a unique platform that links those in need of affordable loans to individuals or corporate bodies to lend out at flexible rates.",
    },

    {
    question:"How does whoUepp work?",
    answer:"It is a platform developed by its technical partners which enables users who wish to lend to each other to seamlessly connect with one another.",
    },

    {
    question:"Who do I get started with whoUepp?",
    answer:"You can begin your application by downloading the whoUepp app from the Google or Apple stores. After downloading the app, sign in with your username, email address, and password and fill in all the required information.",
    },

    {
    question:"How do I profit from this application?",
    answer:"By lending money and earning interest or by having access to affordable loans without the typical application rigours of financial institutions.",
    },

    {
    question:"Are your services available to Lagos residents only?",
    answer:"No. You can access our services from anywhere in Nigeria, as long as you have mobile data on your Android device.",
    },

    {
    question:"I have a complaint; who do I contact?",
    answer:"If you ever encounter any difficulty while using our services, please send us a message at any time to complaints@whoUepp.com  or through any of our social media pages (Facebook, Instagram, and Twitter). We will respond to you as quickly as we can.",
    },

    {
    question:"What happens if I lose my device?",
    answer:"Your account can be easily retrieved. All you need to do is download the app on your new device and sign in with your details.",
    },

    {
    question:"Does whoUepp lend money?",
    answer:"No, whoUepp does not lend money.",
    },

    {
    question:"How do I trust the person to whom I am lending my money?",
    answer:"Users of whoUepp provide information and credentials that helps other users to verify them. ",
    },

    {
    question:"What do I need to be eligible?",
    answer:"You must be at least 18 years old and have a BVN number. ",
    },

    {
    question:"Why is my BVN required?",
    answer:"We request your BVN, as this is used to verify that the individual applying for a loan is the same as the owner of the provided bank account. This is to ensure that your account details cannot be used to apply for a loan without your authorization in the event a 3rd party has access to them. ",
    },

    {
    question:"I don’t know my BVN. What should I do? ",
    answer:"Just dial *565*0#. This will only work if you are making the request from the same phone number currently linked to your bank account.",
    },

    {
    question:"Who has access to my data?",
    answer:"whoUepp never shares your personal details with third parties, unless it is for dedicated business purposes, such as reporting loan defaulters to authorized credit bureaus. It is therefore important to ensure that you repay your loan on time so that your credit history is not negatively affected. If you make timely repayments, this increases your chances of accessing credit from financial institutions in the future.",
    },

    {
    question:"As a lender, what happens if I don't get my money back after the agreed-upon time of payment by the borrower?",
    answer:"Immediately report the borrower to the Platform so that necessary action can be taken.",
    },

    {
    question:"How can I check my expected date of repayment?",
    answer:"To view any information concerning your loan, please log in to your whoUepp account and click on the tab where the ongoing loan is displayed.",
    },

    {
    question:"Can I make a partial payment or instalment when repaying?",
    answer:"Yes. Instalment payments would be determined by the borrower and lender.",
    },

    {
    question:"As a borrower, what happens if I don't pay back my borrowed funds?",
    answer:"You should only take a loan from a user of whoUepp if you are reasonably certain you have the means to repay within the agreed period. Defaulting loans are severely penalized and reported to national credit bureaus. This will affect your credit ratings and your ability to access loans in the future. ",
    },

    {
    question:"What happens if I don’t pay the lender on my due date?",
    answer:"You are advised to take a loan from a user of whoUepp only if you are certain of making a repayment on or before the date you choose to make the repayment. Late repayments also attract extra charges, which means you are liable to pay more than the expected amount if you don't pay when due. Defaulters will also be reported to the National Credit Bureaus, and this will deter your chances of accessing loans from us or any other financial institution.",
    },

    {
    question:"How can I make an early repayment?",
    answer:"If you want to make a payment prior to your due date, please notify the borrower and use the 'Make a Repayment' button, found on the 'My Loans' page on the app. (This would be adjusted depending on how the process is on the app.)",
    },

    {
    question:"What amount can i borrow?",
    answer:"This is determined between the borrower and the lender, within an allowable bracket on the app.",
    },

    {
    question:"What is the interest rate whenever I borrow or lend money?",
    answer:"The interest rate is determined between the borrower and the lender, within an allowable bracket on the app.",
    },
]



