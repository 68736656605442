import React from "react";
import { Text } from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";

const BorrowersResponsibility = () => {
  return (
    <CustomHeader heading="Borrower's Responsibility">
      <Text fontWeight={"bold"} margin={"20px 0"}>
        1. Introduction
      </Text>
      <Text>
        whoUepp is a peer-to-peer lending application (“whoUepp” , “App” ,
        “Platform” or “Application”) developed by its technical partners to
        connect verified borrowers to verified lenders with the goals of
        receiving a loan and funding a loan, respectively. This Borrower
        Agreement (“Agreement”) is between you (“you” and “your” mean each and
        every borrower, including any joint applicant/co-borrower) and Whouepp
        Cooperative Society (referred to as “Whouepp ”, “us,” “our,” or “we”)
        pursuant to how you borrow money through the whoUepp App. When you make
        a loan request and that request results in a loan that is approved by
        the lender and and disbursed by the Platform, your loan will be governed
        by the terms of the Loan Agreement and Promissory Note which is attached
        to this Agreement as Appendix A, and as it may be revised from time to
        time. This Agreement will remain in effect for the duration of the
        applicable loan and will terminate if your loan request is canceled,
        withdrawn, or declined.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        2. Loans
      </Text>
      <Text>
        When you make a request for a loan, you agree to receive and to repay in
        a timely manner the loan that may be disbursed in response to that
        request, subject to your right to cancel the request before confirming
        your application. Your agreement means you agree to repay the money
        loaned by Lenders to you and to abide by the terms of this Agreement and
        all other agreements or disclosures provided to you during the loan
        process and which may be linked to your whoUepp account.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        3. Loan Requests
      </Text>
      <Text>
        You may post one or more loan requests at a time on the App. You may
        have one or more loans outstanding at any given time. When you post a
        loan request on the App, whoUepp Lenders (“Lenders”) will be able to
        review your loan request. Lenders may commit to lend to you per your
        loan request(s). You acknowledge that a Lender’s commitment to provide
        loans to part or all of your loan requests does not confer any rights on
        you or obligate us to approve your loan. You understand that Lenders
        have the discretion whether to lend to you or not. You also expressly
        agree and undertake that Whouepp will not be held liable for any loss
        that you may suffer as a result of our decision not to approve your loan
        request. If at any point prior to disbursement, you no longer want a
        loan under your pending loan request, you can cancel the loan request
        from your account
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        4. Account Verification
      </Text>
      <Text>
        You understand that if we are unable to verify any of your information
        for any reason, we will cancel your application, your loan request will
        not be posted on the App, and this Agreement will be terminated.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        5. Loan Decisions
      </Text>
      <Text>
        We reserve the right to verify any information you submit by requiring
        you to produce appropriate documentation or other proof, and we reserve
        the right to conduct such verification through a third party. You hereby
        authorize us to request and obtain data from a third party, including
        but not limited to your financial institution, credit bureaus,
        government agencies, or any other service provider, to verify any
        information you provide to us in connection with your loan request. We
        may terminate the consideration of your application at any time at our
        sole discretion.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        6. Making Your Loan Repayments
      </Text>
      <Text>
        You hereby authorize us and our third-party partners to debit your bank
        account(s) for the amount of each repayment due on each due date. This
        authorization does not affect your obligation to pay when due, all
        amounts payable on your loan, whether or not there are sufficient funds
        in such bank accounts.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        7. Making Your Loan Repayments
      </Text>
      <Text>
        You hereby authorize us and our third-party partners to debit your bank
        account(s) for the amount of each repayment due on each due date. This
        authorization does not affect your obligation to pay when due, all
        amounts payable on your loan, whether or not there are sufficient funds
        in such bank accounts.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        8. Other Borrowing Obligations
      </Text>
      <Text>
        You undertake that you shall not, in connection with your loan request:
        (i) make any false, misleading or deceptive statements or omissions of
        fact in your loan request; (ii) misrepresent your identity, or describe,
        present or portray yourself as a person other than yourself; (iii) give
        to or receive from, or offer or agree to give to or receive from any
        whoUepp member, Lender or other person any fee, bonus, additional
        interest, kickback or thing of value of any kind except in accordance
        with the terms of your loan; or (iv) represent yourself to any person,
        as a representative, employee, or agent of ours, or purport to speak to
        any person on our behalf. You acknowledge and agree that we may rely
        without independent verification on the accuracy, authenticity, and
        completeness of all information you provide to us. You certify that you
        are resident in Nigeria and the proceeds of the loan will not be used
        for funding any illegal activity.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        5. Cookies And Usage Data
      </Text>
      <Text>
        As a condition to receiving a loan from us, you hereby grant us a
        limited power of attorney and appoint us and/or our designees as your
        true and lawful attorney and agent, with the full power of substitution
        and re-substitution, for you and in your name, place, and stead, in any
        and all capacities, to complete and execute the Loan Agreement and
        Promissory Note(s) in the form attached as Appendix A that reflect the
        accepted terms set forth in each of your final truth in lending
        disclosure(s) as such may be posted from time to time in response to
        your loan request(s) in the on-line account you have established with
        whoUepp where documents are stored and with full power and authority to
        do and perform each and every act and thing requisite and necessary to
        be done in connection with such power as fully to all intents and
        purposes as you might or could do in person (“Power of Attorney” ). This
        Power of Attorney is limited solely to the purpose described above and
        will expire automatically upon the termination of this Borrower
        Agreement. You may revoke this Power of Attorney by contacting us at
        support@whoUepp.com and closing your account with us. However, if a loan
        request has been approved, you must provide such notice before the loan
        is disbursed to your designated account and before the Loan Agreement
        and Promissory Note is executed on your behalf. Once the Loan Agreement
        and Promissory Note is signed by the lender or your designee acting as
        your attorney-in-fact, it is deemed executed on your behalf and shall be
        your valid and binding obligation thereafter
        <Text margin={"20px 0"}>Loan Consummation</Text>
        <Text margin={"10px 0"}>
          YOU AGREE AND ACKNOWLEDGE THAT YOU ARE NOT OBLIGATED TO TAKE ANY LOAN
          UNDER THE TERMS OF THE LOAN AGREEMENT AND PROMISSORY NOTE AND THE LOAN
          TRANSACTION WITH US IS NOT COMPLETED (I.E. CONSUMMATED) UNTIL YOUR
          ABILITY TO CANCEL YOUR LOAN APPLICATION ON THE APP HAS PASSED. YOU
          ACKNOWLEDGE AND UNDERSTAND THAT YOU CAN CANCEL YOUR LOAN REQUEST ANY
          TIME PRIOR TO DISBURSEMENT. YOU ACKNOWLEDGE THAT WE ARE MATERIALLY
          RELYING UPON THIS UNDERSTANDING IN UNDERTAKING THE POTENTIAL
          DISBURSEMENT OF YOUR LOAN THROUGH OUR PLATFORM.
        </Text>
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        10. Intellectual property
      </Text>
      <Text>
        You acknowledge that the App permits access to content that is protected
        by copyrights, trademarks, and other proprietary rights and that these
        intellectual property rights are valid and protected in all media
        existing and your use of the platform shall be governed by the
        applicable intellectual property laws.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        11. Notices
      </Text>
      <Text>
        All notices and other communications to you hereunder may be given by
        email to your registered email address and shall be deemed to have been
        duly given and effective upon transmission. You acknowledge that you
        have control over such an email account and your area on the App and
        that communications from us may contain sensitive and confidential
        information. If your email address changes, you must notify us of the
        change by sending an email to support@whoUepp.com. You also agree to
        notify us of the change by sending an email to support@whoUepp.com if
        there are any changes in your registered residence address, telephone
        number, or name on the App.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        12. Limitation on Liability
      </Text>
      <Text>
        To the extent permitted by applicable law, our liability to you for any
        cause whatsoever, regardless of the form of the action will not exceed
        N2,500 the dispute is pertaining to a lending transaction, our liability
        to you shall not exceed the amount of the lending transaction or N2,500,
        whichever is lower.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        13. No Warranties
      </Text>
      <Text>
        EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, WE MAKE NO
        REPRESENTATIONS OR WARRANTIES TO YOU, INCLUDING, BUT NOT LIMITED TO, ANY
        IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
        PURPOSE. WE DO NOT WARRANT OR GUARANTEE THAT YOUR LOAN REQUEST WILL BE
        FUNDED OR THAT YOU WILL RECEIVE A LOAN AS A RESULT OF POSTING A REQUEST.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        14. Electronic Transactions
      </Text>
      <Text>
        This Agreement is fully subject to your consent to electronic
        transactions and disclosures, which consent is set forth in the terms of
        use for the App.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        15. Dispute Resolution
      </Text>
      <Text>
        This Agreement shall be governed by the Laws of the Federal Republic of
        Nigeria. Any dispute arising out of this Agreement, which cannot be
        settled, by mutual agreement/negotiation within 30 days shall be
        referred to arbitration by a single arbitrator at the Lagos Court of
        Arbitration (“LCA”) and governed by the Arbitration and Conciliation
        Act, Cap A10, Laws of the Federal Republic of Nigeria. The arbitrator
        shall be appointed by the Parties, where Parties are unable to agree on
        the choice of an arbitrator, the choice of arbitration shall be referred
        to the LCA. The findings of the arbitrator and subsequent award shall be
        binding on the Parties and may be enforced through a Nigerian court of
        law. Each Party shall bear its cost in connection with the Arbitration.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        16. Application Law
      </Text>
      <Text>
        This Agreement shall be interpreted and governed by the laws in force in
        the Federal Republic of Nigeria. The Parties submit to Arbitration and
        waive any objections on venue and conflict of laws.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        17. Default And Termination
      </Text>
      <Text>
        You will be deemed in default on your loan (each, an “Event of Default”)
        if you: fail to pay in a timely manner, any amount due on your loan as
        at when due; file or have instituted against you any bankruptcy or
        insolvency proceedings or make any assignment for the benefit of
        creditors; die or are declared dead; commit fraud or make any material
        misrepresentation in this Agreement, or any other documents,
        applications or related materials delivered to us in connection with
        your loan; or fails to abide by the terms of this Agreement.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        18. Event of Default
      </Text>
      <Text>
        Upon the occurrence of an Event of Default, we may exercise any and/or
        all remedies available to us under applicable law, this Agreement, the
        Loan Agreement and Promissory Note, including without limitation, a
        demand that you immediately pay all amounts owed on your loan or
        terminate this Agreement. In the Event of Default on your loan
        repayments, you hereby consent to whatever means we deem fit to
        facilitate the recovery of your loan payments. A debt recovery fee of
        10% of the outstanding loan amount will become payable by you in the
        event of default. An additional 1% accruing interest per day will also
        become payable for every late payment of the repayment sum. Any loan you
        obtain prior to the effective date of termination resulting from
        listings you placed on the App shall remain in full force and effect in
        accordance with their terms.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        19. Fees
      </Text>
      <Text>
        Borrower Commitment Fee: A commitment fee shall be charged on all loans
        approved by the lender and and disbursed by the Platform. You
        acknowledge that you will be informed of the amount payable as a
        commitment fee at the time of your loan request and you agree to pay the
        fee as a condition for the approval and disbursement of your loan
        request. KYC Verification Fee: whoUepp will charge a KYC verification
        fee. You acknowledge that you will be notified of the fee and agree to
        pay the fee as a condition for the approval and disbursement of your
        loan request <br />
        _______________________________________________
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        APPENDIX A
      </Text>
      <Text margin={"20px 0"}></Text>
      <Text margin={"10px 0"}>
        This Loan Agreement and Promissory Note between the Borrower (“you”) and
        the Lender or any holder of this Promissory Note (collectively referred
        to as the “Parties”) is agreed as at the date of the Lender accepting to
        grant the Borrower a loan which is on the ______ of ________________,
        20____. This Agreement applies only to the parties who have signed below
        on behalf of the parties. For value received, I (“Borrower”) promise to
        pay to the order of the Lender or any subsequent holder of this
        Promissory Note (the “Note”) the principal sum of ________________ Naira
        with interest as set forth below. I agree to be legally bound by this
        Note. I have read, understood, and agreed to all of the terms of this
        Note.
      </Text>
      <Text margin={"10px 0"}>
        Interest:This Note bears interest during each calendar month from the
        date hereof until paid, at a fixed rate of ______ (%). Interest is
        calculated on a monthly basis upon the unpaid balance with each payment
        representing 1/12th of a year. Payments: <br />
        Principal and interest are to be paid during and throughout the period
        of _______________ months in the following manner: <br />
        Payments of principal and interest in the amount of ________________
        Naira are to be made by the Borrower to the Lender commencing
        _____________, 20__, and on the same day of each successive month
        thereafter until __________, 20__, when the full amount of unpaid
        principal, together with unpaid accrued interest is due and payable. If
        the monthly anniversary is on the 29th, 30th, or 31st of the month, and
        the following month does not have a 29th, 30th, or 31st day, the monthly
        payment will be due on the last day of the month in which the payment
        was due. The last payment might be of a slightly different amount to
        adjust for rounding. All payments on this Note are to be made in Naira.
        Borrower authorizes whoUepp to debit Borrower’s designated account for
        the amount of each payment due on each due date. Prepayments and Partial
        Payments Borrower may make any payment early, in whole or in part,
        without penalty or premium at any time. Any partial prepayment is
        applied against the principal amount outstanding and does not postpone
        the due date of any subsequent monthly payment unless expressly agreed
        to in writing.
      </Text>
      <Text margin={"10px 0"}>
        If Borrower prepays this Note in part, Borrower agrees to continue to
        make regularly scheduled payments until all amounts due under this Note
        are paid. Lender may accept late payments or partial payments, even
        though marked “paid in full”, without losing any rights under this Note.
        Any regularly scheduled monthly installment of principal and interest
        that is received by Lender before the date it is due shall be deemed to
        have been received on the due date solely for the purpose of calculating
        interest due. Use of FundsBorrower certifies that the proceeds of the
        loan will not be used for the purposes of funding any illegal activity.
        Default and Termination You will be deemed in default on your loan
        (each, an “Event of Default”) if you: fail to pay timely any amount due
        on your loan; file or have instituted against you any bankruptcy or
        insolvency proceedings or make any assignment for the benefit of
        creditors; die or are declared dead; commit fraud or make any material
        misrepresentation in this Agreement, or any other documents,
        applications or related materials delivered to us in connection with
        your loan; or fail to abide by the terms of this Agreement. Event of
        DefaultUpon the occurrence of an Event of Default, we may exercise all
        remedies available to us under applicable law, this Agreement, the Loan
        Agreement and the Promissory Note, including without limitation, a
        demand that you immediately pay all amounts owed on your loan or
        terminate this Agreement. Any loan you obtain prior to the effective
        date of termination resulting from listings you placed on the App shall
        remain in full force and effect in accordance with their terms.
        Electronic TransactionsTHIS AGREEMENT IS FULLY SUBJECT TO BORROWER’S
        CONSENT TO ELECTRONIC TRANSACTIONS AND DISCLOSURES, WHICH CONSENT IS SET
        FORTH IN THE TERMS OF USE FOR THE APP. Applicable LawThe provisions of
        this Note will be governed by the laws of the Federal Republic of
        Nigeria. The unenforceability of any provision of this Note shall not
        affect the enforceability or validity of any other provision of this
        Note.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        APPENDIX B
      </Text>
      <Text margin={"20px 0"}>Borrower Credit Profile Authorization</Text>
      <Text margin={"10px 0"}>
        By clicking the checkbox following this notice and continuing with the
        application process, you understand and agree that you authorize whoUepp
        (referred to as “we”, “us” and “our”) to obtain risk reports and related
        information about you from Credit Bureau or any other risk reporting
        agencies. You also authorize us to verify information in your
        application, and you agree that we may contact third parties to verify
        any such information. As and when appropriate, we may use your consumer
        reports to authenticate your identity, to make credit decisions. You
        direct us to provide on your behalf certain elements of your
        application, as well as risk report information (but not including your
        name) to users of the https://www.whoUepp.com/ website who are
        registered as investor members (“Investor Members”) for purposes of
        making decisions associated with your loan. You authorize us to obtain
        new risk reports and related information each time you request a loan,
        during the processing or closing of a loan to you, or at various times
        during the term of your loan in connection with the servicing,
        monitoring, collection or enforcement of the loan. Payment for the risk
        assessment does not guarantee that you automatically qualify for a loan
        as it is only used as a method of assessing your risk for purposes of
        your loan approval.
      </Text>

      {/* <Text
                fontWeight={'bold'}
                margin={"20px 0"}
            >
                5. Cookies And Usage Data
            </Text>
            <Text>

            </Text> */}
    </CustomHeader>
  );
};

export default BorrowersResponsibility;
