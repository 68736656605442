import React from "react";
import BorrowersHeroSection from "../components/borrowes.jsx/BorrowersHeroSection";
import applyImg from "../assets/apply_loan.png";
import heroImg from "../assets/hero_img.png";
import SignUpSection from "../components/SignUpSection";
import { Faq } from "../components/Faq";
import { Box } from "@chakra-ui/react"

// import faqMan from "../assets/faq_man.png";
import woman from "../assets/woman.png";

const BorrowersPage = () => {
  return (
    <>
      <BorrowersHeroSection
        direction={["column", "column", "row"]}
        title={[
          "Get Access to funds to",
          "simplify your life, with",
          "relevant",
          "requirements.",
        ]}
        description={
          "A lender will credit your wallet and once approved, funds are typically received in your WhoUEpp wallet within 5 minutes. At WhoUEpp, you too can invest in tiny & affordable chunks and still enjoy the same return rates in amazing ways."
        }
        img={heroImg}
      />

      <Box bg="#D9ECE935" my="150px" justifyContent="space-between">
      <BorrowersHeroSection
        direction={["column", "column", "row-reverse"]}
        title={[
          "Receive a quick loan",
          "for your personal or ",
          "professional",
          "requirements.",
        ]}
        description={
          "Apply for a short-term loan to cover expenses like as expanding your small business, remodeling your house, paying your rent, paying for education, paying for medical expenses, and project-based loans."
        }
        img={applyImg}
      />
      </Box>
      <Faq img={woman} />
      <SignUpSection />
    </>
  );
};

export default BorrowersPage;
