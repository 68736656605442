import { Text } from "@chakra-ui/react";
import React from "react";
import CustomHeader from "../components/CustomHeader";

const GurantorAgreement = () => {
  return (
    <CustomHeader heading="Guarantor's Agreement Form">
      <Text fontSize={"16px"} mt="20px">
        In consideration for [name of the Lender] (the “Beneficiary”) entering
        into a Loan Agreement and Promissory Note (the “Contract”) with the
        [name of guarantee holder] (the “Guarantee Holder”) I, [name of
        guarantor] residing at [address of guarantor] (the “Guarantor”), do
        hereby personally guarantee as primary obligor and not merely as surety
        the prompt performance by the Guarantee Holder of all its obligations
        and the payment of the sum of [N payment being guaranteed] under or in
        connection with the Contract when and as same shall become due
      </Text>
      <Text mt={"10px"} fontSize={"16px"}>
        In the event that the Guarantee Holder fails to make payments to the
        Company or fails to discharge according to the said Contract between the
        parties, I hereby guarantee to make full payment to the Beneficiary in
        the same way as if I was the original contracting party with outstanding
        obligations in the Contract. I acknowledge that such payment shall take
        place within a strict limit of 10 calendar days after the guarantee call
        has been made.
      </Text>
      <Text mt={"10px"} fontSize={"16px"}>
        I agree and acknowledge that I am precluded from invoking any payment
        terms or grace periods accorded to the Guarantee Holder and that the
        Guarantee Holder’s default is legally enforceable against me. I
        therefore expressly waive all benefits of discussion and division in
        regard to the Beneficiary and undertake to pay up to the limit of the
        commitment (principal sum and interest alike), without any need for the
        Beneficiary to take legal proceedings against the Guarantee Holder or
        take legal proceedings against other persons who have provided a
        guarantee to the Guarantee Holder.
      </Text>

      <Text fontSize={"16px"}>
        This personal guarantee is willingly entered into this day of _________
        day of _________, 20____
      </Text>
      <Text margin={"10px 0"}>______________________</Text>
      <Text fontSize={"16px"}>Signature</Text>
    </CustomHeader>
  );
};

export default GurantorAgreement;
