import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { AppIcon } from "./Hero";
import googlePlay from "../assets/google-play.svg";
import appStore from "../assets/apple.svg";

export const GetStartedModal = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        _hover={{ background: "#3A49D1" }}
        fontSize={".9em"}
        bg="#3A49D1"
        color="#fff"
        p="10px 30px"
        onClick={onOpen}
      >
        Get Started
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p={"20px"}>
          <ModalHeader
            color={"#1A0031"}
            fontWeight={"bold"}
            fontSize={"1rem"}
            // pb={"50px"}
            // pl={"60px"}
          >
            Download the Whouepp app to get started
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack direction={"row"} alignItems="center" spacing="20px">
              <AppIcon
                borderC={"#0A0013"}
                img={googlePlay}
                subTitle="Download on"
                title="Google Play"
              />
              <AppIcon
                borderC={"#42A293"}
                img={appStore}
                subTitle="Download on the"
                title="App Store"
              />
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
