import React from "react";
import SignUpSection from "../components/SignUpSection";
import Features from "../components/Features";
import Hero from "../components/Hero";
import { Faq } from "../components/Faq";

import HowItWorks from "../components/HowItWorks";
import woman from "../assets/woman.png";

const LandingPage = () => {
  return (
    <>
      {/* <Nav /> */}
      <Hero/>
      <Features />
      <HowItWorks />
      <Faq img={woman} />
      <SignUpSection />
      {/* <Footer /> */}
    </>
  );
};

export default LandingPage;
