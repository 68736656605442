import React from "react";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import signUpPhone from "../../assets/SignupEnterprise.png";
import badges from "../../assets/badges.png";
// import SmallPhone from "../../assets/SmallPhone.png";
import PaddingContainer from "../PaddingContainer";

const SignUp = () => {

    return (  
        <PaddingContainer>
        <Flex
        flexDirection={["column", "column", "row"]}
        justify={"space-between"}
        align={["center", "center", "center"]}
        width="100%"
        data-aos='zoom-out'
      >
        <Box 
        width={["100%", "100%", "50%"]} 
        h="100%"
        >
          <Image
           src={signUpPhone} 
           alt="signUp Phone"
        //    w={["100%", "100%", "100%"]}
            />
            
        </Box>
        <Box
          width={["100%", "100%", "50%"]}
          mt={["20px", "20px", "0"]}
          textAlign={["center", "center", "left"]}
        >
          <Heading
            as="h3"
            color="#212121"
            fontSize={["24px", "28px", "40px", "48px"]}
            fontWeight={"normal"}
            lineHeight={[1.2, 1.2, 1]}
            mb={["10px", "10px", "20px"]}
          >
            Sign up for free.
          </Heading>
          <Heading
            as="h3"
            color="#180656"
            fontSize={["24px", "28px", "40px", "48px"]}
            lineHeight={[1.2, 1.2, 1]}
            mb={["10px", "10px", "20px"]}
          >
            Get Instant Loans.
          </Heading>
          <Heading
            as="h3"
            fontWeight={"normal"}
            fontSize={["24px", "28px", "48px"]}
            lineHeight={[1.2, 1.2, 1]}
            mb={["10px", "10px", "20px"]}
          >
            <Text as="span" color="#180656" fontWeight={"bold"}>
              Become a
            </Text>{" "}
            lender today.
          </Heading>
          <Box mt={["20px", "20px", "40px"]}>
            <Image loading="lazy" src={badges} alt="" />
          </Box>
        </Box>
      </Flex>
      </PaddingContainer>
    );
}
 
export default SignUp;