import React, {useEffect} from "react"
import { Box, Text, ListItem, UnorderedList, Button, Stack, Grid } from "@chakra-ui/react";
import PaddingContainer from "../PaddingContainer";
import AOS from 'aos'
import 'aos/dist/aos.css'


const Pricing = () => {
    useEffect(() => {
        AOS.init({duration: 2000})
    }, [])

    return (
        <PaddingContainer
            bg="#F3F4F5"
            data-aos='zoom-out'>
            <Stack textAlign={"center"} alignItems="center" mb="80px !important">
                <Stack position={"relative"}>
                    <Text
                        position={"absolute"}
                        fontFamily={"Montserrat"}
                        fontWeight="800"
                        color="#3a49d11f"
                        //    left="120px"
                        left={["20px", "100px", "120px"]}
                        top="-15px"
                        fontSize={["2rem", "2.2rem", "2.4em"]}
                        whiteSpace={"nowrap"}
                    >
                        Subscriptions
                    </Text>
                    <Text
                        fontFamily="Montserrat"
                        fontWeight="800"
                        fontSize={["2rem", "2rem", "2.5em"]}
                        color="#074B64"
                    >
                        {" "}
                        <span style={{ color: "#42A293", fontWeight: "bolder" }}>
                            &mdash;
                        </span>{" "}
                        Pricing
                    </Text>
                </Stack>

                <Text
                    maxW={["400px", "400px", "580px"]}
                    textAlign={"center"}
                    color="#434242"
                    lineHeight={"1.7"}
                    fontFamily="Trenda-regular"
                    fontSize={'15px'}
                >
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                </Text>
            </Stack>
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)"
                }}
                gap="30px"
            >
                <Box
                    bg="white"
                    w="300px"
                    borderRadius={"7px"}
                    boxShadow={"sm"}
                    p="30px">
                    <Text
                        fontSize="2xl"
                        fontWeight="bold"
                        mb="20px"
                        fontFamily={"Trenda"}
                        color="#032A34">Basic Plan</Text>
                    <Text fontSize="30px" color="#032A34" fontWeight="bold" mb="10px">#1500 <span style={{ color: '#032A34', fontFamily: "Trenda", fontSize: '14px' }}>/10 Users</span></Text>
                    <UnorderedList h="150px" mb="20px">
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                    </UnorderedList>
                    <Button
                        bg="#0D72A7"
                        color="white"
                        w="80%"
                    >
                        Choose Plan</Button>
                </Box>

                <Box
                    bg="white"
                    p="30px"
                    w="300px"
                    borderRadius={"7px"}
                    boxShadow={"sm"}
                >
                    <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Text
                            fontSize="2xl"
                            color="#032A34"
                            fontWeight="bold"
                            mb="20px"
                            fontFamily={"Trenda"}
                        >
                            Premium Plan</Text>


                        <Box>
                            <Text
                                fontSize="13px"
                                color="white"
                                fontWeight="regular"
                                bg="#0D72A7"
                                mb="20px"
                                borderRadius={"10px"}
                                fontFamily={"Trenda"}
                                padding="5px"
                            >
                                Popular Plan</Text>
                        </Box>

                    </Box>

                    <Text fontSize="30px" color="#032A34" fontWeight="bold" mb="10px">#3000 <span style={{ color: '#032A34', fontFamily: "Trenda", fontSize: '14px' }}>/20 Users</span></Text>
                    <UnorderedList mb="20px" h="150px">
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                    </UnorderedList>
                    <Button
                        bg="#0D72A7"
                        color="white"
                        w="80%"
                    >
                        Choose Plan</Button>
                </Box>

                <Box
                    bg="white"
                    w="300px"
                    borderRadius={"7px"}
                    boxShadow={"sm"}
                    p="30px">
                    <Text fontSize="2xl" fontFamily={"Trenda"} fontWeight="bold" mb="20px" color="#032A34">Customized Plan</Text>
                    <Text fontSize="30px" fontFamily="Trenda" fontWeight="bold" mb="10px">Contact Us </Text>
                    <UnorderedList mb="20px" h="150px">
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                        <ListItem mb="10px" color="#707070">Lorem ipsum dolor sit amet</ListItem>
                    </UnorderedList>
                    <Button
                        bg="#0D72A7"
                        color="white"
                        w="80%"
                    >
                        Choose Plan</Button>
                </Box>
            </Grid>


        </PaddingContainer>
    );
}

export default Pricing;