import React, {useEffect} from "react"
import {Image, Stack, Text } from "@chakra-ui/react";
import PaddingContainer from "../PaddingContainer";
import how1 from "../../assets/enterpriseHTW1.png";
import how2 from "../../assets/enterpriseHTW2.png";
import AOS from 'aos'
import 'aos/dist/aos.css'

const HowItWorks = () => {

  useEffect(() => {
    AOS.init({duration: 2000})
}, [])

    return (
      <PaddingContainer bg="#F3F4F5" data-aos="zoom-in">
        <Stack textAlign={"center"} alignItems="center" mb="80px !important">
          <Stack position={"relative"}>
            <Text
              position={"absolute"}
              fontFamily={"Montserrat"}
              fontWeight="800"
              color="#3a49d11f"
              left="120px"
              top="-15px"
              fontSize="2em"
              whiteSpace={"nowrap"}
            >
              App Usage
            </Text>
            <Text
              fontFamily="Montserrat"
              fontWeight="bold"
              fontSize={["2rem", "2rem", "2.5em"]}
              color="#074B64"
            >
              {" "}
              <span style={{ color: "#42A293", fontWeight: "bolder" }}>
                &mdash;
              </span>{" "}
              How It Works
            </Text>
          </Stack>

          <Text
            maxW={["400px", "400px", "580px"]}
            textAlign={"center"}
            color="#434242"
            lineHeight={"1.7"}
            fontSize={"15px"}
            fontFamily="Trenda-regular"
          >
            We understand that borrowers and lenders alike have unique needs and
            goals, and we strive to meet those needs with our comprehensive
            peer-to-peer lending services.
          </Text>
        </Stack>

        <Stack spacing={"150px"}>
          <Stack
            display={"flex"}
            flexDirection={{ base: "column", md: "column", lg: "row" }}
            alignItems={{ base: "center", md: "center", lg: "flex-start" }}
            justifyContent={["center", "center", "space-between"]}
            spacing={["20px", "20px", "30px", "30px"]}
          >
            <Stack
              flex="1"
              w={{ base: "100%", md: "100%", lg: "50%" }}
              mr={{ base: "", md: "", lg: "30px" }}
            >
              <Text
                fontFamily="Montserrat"
                color="#212121"
                fontSize={["1.5em", "1.7em", "2.3em"]}
                maxW={["300px", "400px", "450px"]}
              >
                Get access to funds to <br />
                <span style={{ fontWeight: "800", color: "#074B64" }}>
                  simplify your life, with relevant{" "}
                </span>
                requirements
              </Text>
              <Text
                fontFamily="Trenda-regular"
                color="#434242"
                fontSize={"15px"}
                my={["10px", "10px", "20px", "20px"]}
                lineHeight={"1.7"}
              >
                Our enterprise platform allows borrowers to access funds from
                lenders within a close group or an organisation
              </Text>
            </Stack>
            <Image
              w={{ base: "100%", md: "70%", lg: "50%" }}
              flex="1"
              src={how1}
              alt="happy people"
            />
          </Stack>

          <Stack
            display={"flex"}
            flexDirection={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
            alignItems={{ base: "center", md: "center", lg: "flex-start" }}
            justifyContent={["center", "center", "space-between"]}
            spacing={["20px", "20px", "30px", "30px"]}
          >
            <Image
              loading="lazy"
              w={{ base: "100%", md: "70%", lg: "50%" }}
              flex="1"
              src={how2}
              alt="happy people"
              mr={{ base: "", md: "", lg: "50px" }}
              mt={{ base: "20px", md: "30px", lg: "" }}
            />
            <Stack flex="1" w={{ base: "100%", md: "100%", lg: "50%" }}>
              <Text
                fontFamily="Montserrat"
                //  fontWeight="400"
                color="#212121"
                maxW={["300px", "400px", "450px"]}
                fontSize={["1.5em", "1.7em", "2.3em"]}
              >
                Get access to funds to <br />
                <span style={{ fontWeight: "800", color: "#074B64" }}>
                  simplify your life, with relevant{" "}
                </span>
                requirements
              </Text>
              <Text
                fontFamily="Trenda-regular"
                color="#434242"
                my={["10px", "10px", "20px", "20px"]}
                lineHeight={"1.7"}
                fontSize={"15px"}
              >
                A lender within your organisation will credit your wallet and once approved, funds are
                typically received in your WhoUEpp wallet within 5 minutes. At
                WhoUEpp, you too can invest in tiny & affordable chunks and
                still enjoy the same return rates in amazing ways.
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </PaddingContainer>
    );
}
 
export default HowItWorks;