import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React,{useEffect} from "react"
import AOS from 'aos'
import 'aos/dist/aos.css'
import signUpPhone from "../assets/signUpPhone.png";
import badges from "../assets/badges.png";

const SignUpSection = () => {

  useEffect(() => {
    AOS.init({duration: 2000})
}, [])

  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      justify={"space-between"}
      align={["center", "center", "center"]}
      width="100%"
      px={["20px", "40px", "60px"]}
      pt={["80px", "80px", "100px"]}
      pb={["30px", "30px", "0"]}
      data-aos='zoom-out'
    >
      <Box width={["100%", "100%", "45%"]}>
        <Image src={signUpPhone} alt="visuals of phones" />
      </Box>
      <Box
        width={["100%", "100%", "45%"]}
        mt={["20px", "20px", "0"]}
        textAlign={["center", "center", "left"]}
      >
        <Heading
          as="h3"
          color="#212121"
          fontSize={["24px", "28px", "40px", "48px"]}
          fontWeight={"normal"}
          lineHeight={[1.2, 1.2, 1]}
          mb={["10px", "10px", "20px"]}
        >
          Sign up for free.
        </Heading>
        <Heading
          as="h3"
          color="#180656"
          fontSize={["24px", "28px", "40px", "48px"]}
          lineHeight={[1.2, 1.2, 1]}
          mb={["10px", "10px", "20px"]}
        >
          Get Instant Loans.
        </Heading>
        <Heading
          as="h3"
          fontWeight={"normal"}
          fontSize={["24px", "28px", "48px"]}
          lineHeight={[1.2, 1.2, 1]}
          mb={["10px", "10px", "20px"]}
        >
          <Text as="span" color="#180656" fontWeight={"bold"}>
            Become a
          </Text>{" "}
          lender today.
        </Heading>
        <Box mt={["20px", "20px", "40px"]}>
          <Image loading="lazy" src={badges} alt="" />
        </Box>
      </Box>
    </Flex>
  );
};

export default SignUpSection;
