import React from "react";
import LendersHeroSection from "../components/borrowes.jsx/LendersHeroSection";
import lenderHero2 from "../assets/lenderHero2.png";
import lenderHero from "../assets/lenderhero_img.png";
import SignUpSection from "../components/SignUpSection";
import { Faq } from "../components/Faq";

// import faqMan from "../assets/faq_man.png";
import woman from "../assets/woman.png";
import { Box } from "@chakra-ui/react";

const LendersPage = () => {
  return (
    <>
      <LendersHeroSection
        direction={["column", "column", "row"]}
        title={[
          "Invest in someone else's",
          "aspirations while you",
          "move closer to financial",
          "independence.",
        ]}
        description={
          "Whether you're a beginner or an experienced lender, let your money work for you with our smart lending solutions. You can start lending to thousands of borrowers, take advantage of an appealing interest rate, and manage your own risks."
        }
        img={lenderHero}
      />

      <Box bg="#D9ECE935"  my="150px" justifyContent="space-between">
        <LendersHeroSection
          direction={["column", "column", "row-reverse"]}
          title={["Let your money help", "others work for you!"]}
          description={
            "Whether you're a novice or an experienced lender, let your money work for you with our smart lending solutions. You can start lending to thousands of borrowers, take advantage of an appealing interest rate, and control your own risks."
          }
          img={lenderHero2}
        />
      </Box>
      <Faq img={woman} />
      <SignUpSection />
    </>
  );
};

export default LendersPage;
