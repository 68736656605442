import { Stack } from "@chakra-ui/react";

function PaddingContainer({ children, ...props }) {
  return (
    <Stack p={["50px 30px", "50px 30px", "50px 30px", "80px 80px"]} {...props}>
      {children}
    </Stack>
  );
}

export default PaddingContainer;
