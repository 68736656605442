import React, { useEffect } from "react";
import { Image, Stack, Text } from "@chakra-ui/react";
import googlePlay from "../assets/google-play.svg";
import appStore from "../assets/apple.svg";
import heroImg from "../assets/hero-img.png";
import PaddingContainer from "./PaddingContainer";
import AOS from "aos";
import "aos/dist/aos.css";

function Hero() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <PaddingContainer>
      <Stack
        direction={["column", "column", "row"]}
        position="relative"
        alignItems={"center"}
        data-aos="fade-up"
      >
        <Stack maxW="500px">
          <Text
            fontSize={["2em", "2em", "2em", "3em"]}
            fontFamily="Montserrat"
            fontWeight={"300"}
            color="#2D2C2C"
          >
            Welcome to Our
            <br />
            <span style={{ color: "#180656", fontWeight: "800" }}>
              Peer-to-Peer Lending
            </span>{" "}
            Platform
          </Text>
          <Text
            fontFamily={"Trenda-regular"}
            color="#434242"
            lineHeight={"1.7"}
            maxW="400px"
            mb="20px !important"
          >
            Our platform enables borrowers to access funds quickly and easily
            while allowing lenders to earn competitive returns on their
            investments.
          </Text>
          <Stack direction={"row"} alignItems="center" spacing="20px">
            <AppIcon
              borderC={"#0A0013"}
              img={googlePlay}
              subTitle="Download on"
              title="Google Play"
            />
            <AppIcon
              borderC={"#42A293"}
              img={appStore}
              subTitle="Download on the"
              title="App Store"
            />
          </Stack>
        </Stack>
        {/* <Stack position={"relative"} border="1px solid red" w> */}
        <Stack
          filter="blur(61px)"
          right="300px"
          h="350px"
          w="200px"
          top="-200px"
          bg="#1806561f"
          position={"absolute"}
        />
        <Stack
          w="250px"
          h="150px"
          bottom={"-90px"}
          right="0"
          bg="#42a293ba"
          filter={"blur(61px)"}
          position={"absolute"}
        />

        <Image
          display={["none", "none", "block", "block"]}
          w={["450px", "450px", "450px", "850px"]}
          top="-40px"
          right={["0px", "0px", "0px", "-60px"]}
          // h="600px"
          src={heroImg}
          alt="hero-img"
          position={"absolute"}
        />
      </Stack>
    </PaddingContainer>

    // </Stack>
  );
}

export default Hero;

export const AppIcon = ({ borderC, img, subTitle, title }) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      borderRadius={"7px"}
      border={`1px solid ${borderC}`}
      p="3px 25px"
      spacing={"10px"}
      cursor="pointer"
    >
      <Image loading="lazy" w="15px" src={img} alt="app-logo" />
      <Stack spacing={"-1"}>
        <Text fontSize={".5em"} color="#0A0013">
          {subTitle}
        </Text>
        <Text fontSize={".9em"} color="#0A0013" fontFamily={"Trenda-bold"}>
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};
