import React, { useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import woman from "../assets/woman.png";
import { faqs } from "../DummyData";

const FaqsPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Box
      bg={"#CFE5E3"}
      p={[
        "20px 20px 0px 20px",
        "20px 20px 0px 20px",
        "20px 20px 0px 20px",
        "20px 20px 0px 50px",
      ]}
      w={"100%"}
      data-aos="fade-up"
    >
      <Flex
        direction={["column", "column", "column", "row"]}
        align={["center", "center", "center", "flex-start"]}
      >
        <Box mt={"100px"} position={"relative"}>
          <Text
            color={"#3A49D1"}
            fontSize={"3.5rem"}
            fontWeight={"bold"}
            ml={"210px"}
            mb={"-30px"}
            opacity={"20%"}
            display={["none", "none", "block", "block"]}
          >
            FAQs
          </Text>
          <Box
            borderTop={"3px solid #42A293"}
            w={"30px"}
            rounded={"lg"}
            position={"absolute"}
            left={"-35px"}
            top={"80px"}
            display={["none", "none", "block", "block"]}
          ></Box>
          <Text
            color={"#1A0031"}
            fontSize={"2.7rem"}
            fontWeight={"bold"}
            lineHeight={1.2}
          >
            Frequently Ask <br /> Questions
          </Text>
          <Text color={"#434242"} fontSize={"1.2rem"} mt={"20px"}>
            We know you have questions for us <br /> and trust us, we got you!
          </Text>
        </Box>
        <Image
          loading="lazy"
          src={woman}
          alt="Smiling woman"
          w={"450px"}
          mt={["50px", "50px", "50px", "0px"]}
        />

        <Box
          bg={"#FFFFFF"}
          rounded={"lg"}
          w={["100%", "100%", "100%", "48%"]}
          h="500px"
          overflowY="scroll"
          justify={["center", "center", "center", "flex-end"]}
          ml={["0px", "0px", "0px", "-210px"]}
          p={"40px 20px"}
          mb={"15px"}
        >
          {faqs.map((faq, index) => (
            <Accordion w={"100%"} key={index} allowMultiple>
              <AccordionItem
                bg={"#F1F9F8"}
                rounded={"lg"}
                p={"5px"}
                mb={"25px"}
                border={"1.2px solid #D6D5D5"}
              >
                  <AccordionButton>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      color={"#1A0031"}
                      fontSize={"1rem"}
                      fontWeight={"semibold"}
                      py={"7px"}
                    >
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                <AccordionPanel pb={4} fontSize={"15px"}>
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default FaqsPage;
