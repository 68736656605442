import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import BorrowersPage from "./pages/BorrowersPage";
import LendersPage from "./pages/LendersPage";
import Nav from "./components/Nav";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/TermsAndCondition";
import Faqs from "./pages/FaqsPage";
import Guarantor from "./pages/GuarantorAgreement";
import BorrowersRes from "./pages/BorrowersRes";
import LendersRes from "./pages/LenderRes";
import Footer from "./components/Footer";
import Enterprise from "./pages/Enterprise";
import HowItWorks from "./pages/HowItWorksPage";
// import { lazy } from "react";

function App() {
  // const BorrowersPage = lazy(() => import("./pages/BorrowersPage"));

  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/borrowers-page" element={<BorrowersPage />} />
        <Route path="/lenders-page" element={<LendersPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<Terms />} />
        <Route path="/guarantor-agreement" element={<Guarantor />} />
        <Route path="/borrower-res" element={<BorrowersRes />} />
        <Route path="/lender-res" element={<LendersRes />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/enterprise" element={<Enterprise />} />
        <Route path="/howitworks" element={<HowItWorks />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
