import React,{useEffect} from "react"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";

export const Faq = ({ img }) => {
  useEffect(() => {
    AOS.init({duration: 2000})
}, [])

  return (
    <Box
      bg={"#CFE5E3"}
      id="faq"
      p={["20px 20px 0px 20px", "20px 20px 0px 20px", "20px 20px 0px 20px", "20px 20px 0px 50px"]}
      w={"100%"}
      data-aos='fade-up'
    >
      <Flex
        direction={["column", "column", "column", "row"]}
        align={["center", "center", "center", "flex-start"]}
      >
        <Box mt={"100px"} position={"relative"}>
          <Text
            color={"#3A49D1"}
            fontSize={"3.5rem"}
            fontWeight={"bold"}
            ml={"210px"}
            mb={"-30px"}
            opacity={"20%"}
            display={["none", "none", "block", "block"]}
          >
            FAQs
          </Text>
          <Box
            borderTop={"3px solid #42A293"}
            w={"30px"}
            rounded={"lg"}
            position={"absolute"}
            left={"-35px"}
            top={"80px"}
            display={["none", "none", "block", "block"]}
          ></Box>
          <Text
            color={"#1A0031"}
            fontSize={"2.7rem"}
            fontWeight={"bold"}
            lineHeight={1.2}
          >
            Frequently Ask <br /> Questions
          </Text>
          <Text color={"#434242"} fontSize={"1.2rem"} mt={"20px"}>
            We know you have questions for us <br /> and trust us, we got you!
          </Text>
        </Box>
        <Image
          loading="lazy"
          src={img}
          alt="Smiling woman"
          w={"450px"}
          mt={["50px", "50px", "50px", "0px"]}
        />
        <Box
          bg={"#FFFFFF"}
          rounded={"lg"}
          w={["100%", "100%", "100%", "48%"]}
          justify={["center", "center", "center", "flex-end"]}
          ml={["0px", "0px", "0px", "-210px"]}
          p={"40px 20px"}
          mb={"15px"}
        >
          <Accordion w={"100%"} defaultIndex={[0]} allowMultiple>
            <AccordionItem
              bg={"#F1F9F8"}
              rounded={"lg"}
              p={"5px"}
              mb={"25px"}
              border={"1.2px solid #D6D5D5"}
            >
              {/*<h2 style={{ borderBottom:"2px solid #42A293"}} >*/}
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    color={"#1A0031"}
                    fontSize={"1.3rem"}
                    fontWeight={"semibold"}
                    py={"7px"}
                  >
                  What is peer-to-peer lending?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {/* <Divider color={"#93DFD3 !important"} /> */}
              <AccordionPanel pb={4} fontSize={"15px"}>
              Peer-to-peer (P2P) lending enables individuals to obtain loans directly from other individuals, cutting out the financial institution as the middleman.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem
              bg={"#F1F9F8"}
              rounded={"lg"}
              p={"5px"}
              mb={"25px"}
              border={"1.2px solid #D6D5D5"}
            >
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    color={"#1A0031"}
                    fontSize={"1.4rem"}
                    fontWeight={"semibold"}
                    py={"7px"}
                  >
                    What is the whoUepp app?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {/*<Divider style={{ color:"#93DFD3 !important"}}  />*/}
              <AccordionPanel pb={4} fontSize={"15px"}>
              whoUepp is an app that uses the peer-to-peer mechanism, creating a unique platform that links those in need of affordable loans to individuals or corporate bodies to lend out at flexible rates.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem
              bg={"#F1F9F8"}
              rounded={"lg"}
              p={"5px"}
              mb={"25px"}
              border={"1.2px solid #D6D5D5"}
            >
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    color={"#1A0031"}
                    fontSize={"1.3rem"}
                    fontWeight={"semibold"}
                    py={"7px"}
                  >
                   How does whoUepp work?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              {/*<Divider color={"#93DFD3 !important"} />*/}
              <AccordionPanel pb={4} fontSize={"15px"}>
              It is a platform developed by its technical partners which enables users who wish to lend to each other to seamlessly connect with one another.
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Flex align={"center"} justify={"space-between"} mt="40px">
            <Text 
            color={"#212121"}
            fontSize={"14px"}
             fontWeight={"semibold"}
             w={["5s0%", "45%", "55%"]}
             mr="10px"
             >
              Got More Questions in mind ?
            </Text>
            <Link to="/faqs">
            <Button
              _hover={{ background: "#3A49D1" }}
              bg="#3A49D1"
              color={"#F8F8F8"}
              fontSize={["sm", "sm", "sm", "md"]}
              w={["85%", "45%", "100%"]}
              // p={"20px"}
            >
              Read More on FAQs
            </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
