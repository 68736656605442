import {
  Box,
  Button,
  Image,
  Stack,
  Text,
  useDisclosure,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "../assets/nav-logo.svg";
import { ContactUsModal } from "./ContactUsModal";
import { MdClear, MdMenu } from "react-icons/md";
import { BsArrowDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import { GetStartedModal } from "./GetStartedModal";

function Nav() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [showMenu, setShowMenu] = useState(null);
  const menuOpen = useBreakpointValue([false, false, true, true]);
  const [activeMenuItem, setActiveMenuItem] = useState("Enterprise");


  useEffect(() => {
    setShowMenu(menuOpen);
  }, [menuOpen]);

  return (
    <Box w={"100%"}>
      <Stack
        direction={["column", "column", "row", "row"]}
        alignItems={["center"]}
        justifyContent={"space-between"}
        p={["40px 20px", "40px 20px", "40px 5px", "40px 80px"]}
        fontFamily={"Trenda-regular"}
      >
        <Box
          display={"flex"}
          w={["100%", "100%", "20%", "30%"]}
          alignItems={["flex-start", "flex-start", "flex-start", "flex-start"]}
        >
          <Link to="/">
            <Image w="110px" src={logo} alt="logo" />
          </Link>
        </Box>
        <Box>
          <Stack
            w={"100%"}
            fontWeight={"bold"}
            fontSize=".93em"
            direction={["column", "column", "row", "row"]}
            alignItems="center"
            justifyContent={["center", "center", "flex-end", "flex-end"]}
            p={[null, null, "10px 10px", "20px 5px"]}
            fontFamily={"Trenda-regular"}
            display={showMenu ? "flex" : "none"}
          >
            <Link to="/#feature">
              <Text
                pr={["0px", "0px", "10px", "20px"]}
                pb={["10px", "10px", "0px", "0px"]}
              >
                Feature
              </Text>
            </Link>

            <Link to="/howitworks" style={{ zIndex: 10000 }}>
              <Text
                pr={["0px", "0px", "10px", "20px"]}
                pb={["10px", "10px", "0px", "0px"]}
              >
                How it works
              </Text>
            </Link>

          <Box style={{ position: "relative", zIndex: "99999"}}>
            <Menu >
              <MenuButton
                pr={["0px", "0px", "20px", "20px"]}
                pb={["10px", "10px", "0px", "0px"]}
                cursor="pointer"
                onMouseEnter={() => setShowMenu("enterprise")}
                onMouseLeave={() => setShowMenu(menuOpen)}
              >
                <Box display="flex" alignItems="center" style={{ zIndex: "9999" }}>
                  <Text style={{ fontWeight: 'bold' }}>{activeMenuItem}</Text>
                  <BsArrowDown />
                </Box>
              </MenuButton>

              <MenuList zIndex="9999" style={{ minWidth: "120px", maxWidth: "120px" }} >
                <MenuItem>
                  <Link to="/">
                    <Text onClick={() => setActiveMenuItem("General")}>General</Text>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/enterprise">
                    <Text onClick={() => setActiveMenuItem("Enterprise")}>Enterprise</Text>
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
            </Box>

            <Link to="/#faq" style={{ zIndex: 10000 }}>
              <Text
                pr={["0px", "0px", "20px", "20px"]}
                pb={["10px", "10px", "0px", "0px"]}
              >
                FAQs
              </Text>
            </Link>

            <a href="#contact" style={{ zIndex: 10000 }}>
              <button onClick={() => onOpen()}>Contact Us</button>
            </a>
            <Button
              _hover={{ background: "#3A49D1" }}
              fontSize={".9em"}
              bg="#3A49D1"
              color="#fff"
              p="10px 30px"
              display={["block", "block", "none", "none"]}
            >
              Get Started
            </Button>
          </Stack>

          <Box
            position={"absolute"}
            right={"20px"}
            top={"40px"}
            display={["block", "block", "none", "none"]}
            onClick={() => setShowMenu(!showMenu)}
          >
            {showMenu ? <MdClear size="1.5rem" /> : <MdMenu size="1.5rem" />}
          </Box>
        </Box>
        <Stack direction={"row"} alignItems="center">
          <GetStartedModal />
        </Stack>
      </Stack>
      <ContactUsModal {...{ isOpen, onClose }} />
    </Box>
  );
}

export default Nav;