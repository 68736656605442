import { Box, Heading } from "@chakra-ui/react";
import React from "react";

const CustomHeader = ({ children, heading }) => {
  return (
    <Box py={"20px"} px={["50px", "50px", "100px", "200px"]}>
      <Heading textAlign={"center"} fontWeight={"bold"} fontSize={"30px"}>
        {heading}
      </Heading>
      {children}
    </Box>
  );
};

export default CustomHeader;
