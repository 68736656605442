import React from "react";
import { Image, Stack, Text } from "@chakra-ui/react";
import heroImg from "../../assets/HeroEnterprise.png";


const Hero = () => {
  return (
    <Stack
      display={"flex"}
      flexDirection={{ base: "column", md: "row", lg: "row" }}
      alignItems={{ sm: "center", md: "center", lg: "center" }}
      justifyContent={{ sm: "center", md: "center", lg: "space-between" }}
      p={["50px 30px", "50px 30px", "0 80px"]}
    >
      <Stack w={{ sm: "100%", md: "100%", lg: "50%" }}>
        <Text
          fontSize={["2em", "2em", "2em", "3em"]}
          fontFamily="Montserrat"
          fontWeight={"300"}
          color="#2D2C2C"
        >
          Welcome to Our
          <br />
          <span style={{ color: "#074B64", fontWeight: "800" }}>
            Enterprise Peer-to-Peer
          </span>{" "}
          Lending
        </Text>
        <Text
          fontFamily={"Trenda-regular"}
          color="#434242"
          lineHeight={"1.7"}
          maxW="400px"
          mb="20px !important"
        >
          Our Enterprise platform enables borrowers to access funds quickly and easily
          while allowing lenders to earn competitive returns on their
          investments.
        </Text>
      </Stack>
      <Stack w={{ sm: "100%", md: "100%", lg: "70%" }} position={"relative"}>
        <Stack
          filter="blur(61px)"
          right="60%"
          h="350px"
          w="200px"
          top="-200px"
          bg="#1806561f"
          position={"absolute"}
        />
        <Stack
          w="250px"
          h="150px"
          bottom={"-400px"}
          right="0"
          bg="#42a293ba"
          filter={"blur(90px)"}
          position={"absolute"}
        />

        <Image w={"100%"} src={heroImg} alt="hero-img" />
      </Stack>
    </Stack>
  );
};

export default Hero;
