import { Box, Image, Stack, Text } from "@chakra-ui/react";
import googlePlay from "../../assets/google-play.svg";
import appStore from "../../assets/apple.svg";
// import PaddingContainer from "../PaddingContainer";

function BorrowersHeroSection({ img, description, title, direction, mr }) {
  return (
    // <PaddingContainer>
      <Stack
        width={"100%"}
        direction={direction}
        // position="relative"
        alignItems={"center"}
        justify={["space-between"]}
        p={["0 30px", "20px 30px", "20px 30px", "40px 80px"]}
        // py="70px"
      >
        <Stack width={["100%", "100%", "48%"]} maxW="500px" mr={mr}>
          <Text
            fontSize={"2em"}
            fontFamily="Montserrat"
            fontWeight={"300"}
            color="#2D2C2C"
          >
            {title?.[0]}
            <br />
            <span style={{ color: "#180656", fontWeight: "800" }}>
              {title?.[1]}
            </span>{" "}
            <span style={{ color: "#180656", fontWeight: "800" }}>
              {title?.[2]}
            </span>{" "}
            {title?.[3]}
          </Text>
          <Text
            fontFamily={"Trenda-regular"}
            fontSize={["19px"]}
            color="#434242"
            lineHeight={"1.7"}
            maxW="470px"
            mb="20px !important"
          >
            {description}
          </Text>
          <Stack direction={"row"} alignItems="center" spacing="20px">
            <AppIcon
              borderC={"#0A0013"}
              img={googlePlay}
              subTitle="Download on"
              title="Google Play"
            />
            <AppIcon
              borderC={"#42A293"}
              img={appStore}
              subTitle="Download on the"
              title="App Store"
            />
          </Stack>
        </Stack>
        <Box width={["100%", "100%", "48%"]} mr={mr}>
          <Image
            loading="lazy"
            h="400px"
            // h="100%"
            src={img}
            alt="hero-img"
            mt={["20px", "30px", 0]}
            // position={"absolute"}
          />
        </Box>
      </Stack>
    // </PaddingContainer>

    // </Stack>
  );
}

export default BorrowersHeroSection;

export const AppIcon = ({ borderC, img, subTitle, title }) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      borderRadius={"7px"}
      border={`1px solid ${borderC}`}
      p="3px 25px"
      spacing={"10px"}
      h="60px"
    >
      <Image w="15px" src={img} alt="app-logo" />
      <Stack spacing={"-1"}>
        <Text fontSize={".5em"} color="#0A0013">
          {subTitle}
        </Text>
        <Text fontSize={".9em"} color="#0A0013" fontFamily={"Trenda-bold"}>
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};
