import React from "react";
import { Text } from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";

const LenderResponsibility = () => {
    return (
      <CustomHeader heading="Lender's Responsibility">
        <Text fontWeight={"bold"} margin={"20px 0"}>
          1. Introduction
        </Text>
        <Text>
          whoUepp is a peer-to-peer lending application (“whoUepp”, “App”,
          “Platform” or “Application”) developed by its technical partners to
          connect verified borrowers to verified lenders with the goals of
          receiving a loan and funding a loan. This Agreement outlines the terms
          and conditions (the “Terms”) between you (a “Lender”, or “you”) and
          Whouepp Cooperative Society (“whoUepp", “we”, or “us”) pursuant to
          which you agree to lend money through the whoUepp App to third-party
          borrowers (the “Borrower”). It is important that you read these Terms
          carefully. We strongly recommend that you seek independent legal and
          financial advice when considering whether to lend your money through
          this Platform.
          <Text margin={"20px 0"}>
            We do not provide any advice, nor do we make any recommendations to
            you. No information provided to you in relation to the Platform or
            any Borrower may be deemed, under any circumstance, to be financial
            or professional advice. The Platform only allows you to lend funds
            to Borrowers on the Platform. It is solely your responsibility to
            assess whether the Platform is suitable or appropriate for your
            needs and to seek professional advice prior to making a decision to
            use the Platform. Please be aware that execution of these Terms does
            not imply that we have confirmed that the use of the Platform and
            our Services are suitable or appropriate for you. We advise you to
            consider whether lending through the Platform meets your required
            risk levels and lending objectives, and you should only commit such
            funds that you are able to financially bear the risk of losing,
            considering your other financial commitments. By using the Platform,
            you confirm that you have the necessary experience and knowledge to
            understand the risks involved, or that you have taken advice from a
            qualified professional advisor to assist you in understanding the
            risks involved. You confirm your agreement to these Terms either by
            registering on our online application system or by signing a copy of
            these Terms. You should read these Terms carefully before making an
            online application or signing a copy of them, and you should retain
            a copy for future reference. By signing electronically below, you
            agree to the following terms together with the Terms of Use, consent
            to our Privacy Policy, agree to transact business with us and
            receive communications relating to the Agreement electronically.
          </Text>
        </Text>
        <Text fontWeight={"bold"} margin={"20px 0"}>
          2. Lender Identification
        </Text>
        <Text>
          As part of the account opening process, you will be asked to provide
          personal information about yourself. whoUepp will use this information
          to verify your identity using several electronic checks. You agree
          that we may pass your personal information to our Service Providers or
          any other recognized third-party company solely for the purposes of
          verifying your identity, performing anti-money laundering checks and
          fraud prevention. We do not have to accept you as a Lender or accept
          any funds from you until we have satisfied ourselves, at our absolute
          discretion, that we have adequate and appropriate information to
          accept you as a Lender. We do not have to give you any reason for
          rejecting or delaying an application from you.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          3. Lender’s Representations and Warranties
        </Text>
        <Text>
          Representations and warranties are statements and promises made by you
          to whoUepp, which we rely on as being accurate in our dealings with
          you. You make the following representations and warranties to us at
          the time of agreeing to these Terms and every time you use the
          Platform or Services, including every time you are matched to a
          Borrower: You are over the age of 18 years; You are of sound mind and
          have the capacity to enter into these Terms and be matched to each
          Borrower and Loan Agreement; You hold a bank account in Nigeria in
          your own name and will use this account to transfer money to your
          wallet on whoUepp when you loan money to a Borrower and to receive
          repayment of loans made; You have carefully considered the risks
          involved with using the Platform, the Services and being matched to
          Loan Agreements and also the risks associated with debt financing,
          including but not limited to the possibility of losing some or all the
          money you lend; If you are registering or accepting these Terms on
          behalf of a legal entity such as a company, trust or partnership, you
          are legally authorized to do so and we may request evidence of such
          legal entitlement (by way of a copy of any document which shows the
          valid and subsisting authorization); You (as an individual or a legal
          entity) have only registered as a Lender once with whoUepp, except
          where you have registered as a Lender in your personal capacity and
          have disclosed to us that you are seeking to register as a Lender on
          behalf of a separate legal entity or a third party; and You are not
          breaching any laws or regulations that are applicable to you or any
          company, trust or partnership upon whose instructions you are acting.
        </Text>
        <Text fontWeight={"bold"} margin={"20px 0"}>
          4. Risk Acknowledgment
        </Text>
        <Text>
          You acknowledge and confirm that you have carefully considered the
          risks involved in lending your money through our Platform and that
          your lending to any Borrower or participation in any Loan Agreement is
          entirely at your own risk. You acknowledge that by making a lending
          commitment and being matched to Loan Agreements you are engaging in
          debt financing and that at no time do you as Lender lend money to
          whoUepp or any director or employee of whouepp. You understand and
          acknowledge that while whouepp may take reasonable steps to verify the
          identity of the Borrower, your decision to lend money to any borrower
          through the Platform carries certain risks, including that the
          Borrower may default and you may not recover some or all of the money
          advanced as a loan and interest thereon. whoUepp will have/bear no
          liability in the event that you are unable to recover some or all of
          the money loaned to the Borrower. You acknowledge and confirm that you
          have made your own independent decision to make lending commitments
          and to be matched to Borrowers and Loan Agreements and that your
          decision is based on such information as you deem appropriate under
          the circumstances and is without reliance upon whoUepp or any member
          of whouepp. You acknowledge that subject to these Terms, you will not
          be entitled to receive repayment of any money until the Borrower to
          whom you have lent money has made a repayment in accordance with the
          terms of the applicable Loan Agreement. You also expressly agree and
          acknowledge that whoUepp shall not be liable for any loss that you may
          suffer (however arising) as a result of your decision to lend money to
          a Borrower through the Platform.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          5. Insurance
        </Text>
        <Text>
          You understand and accept that certain loans may, at your request, be
          eligible for insurance pursuant to an insurance policy effected by
          whoUepp in respect of a loan funded by you. Such insurance shall only
          take effect subject to the following conditions: The insurance is
          requested by you prior to or at the time of funding a Borrower’s loan
          request; Your payment of the requisite insurance fees as stipulated by
          whoUepp; Your fulfilment of all KYC and documentation requirements,
          and any other pre-conditions stipulated by whouepp; Provision of true
          and accurate information and disclosures on all matters relating to
          your funding of a loan request; You understand and agree that any
          insurance cover which you request and is accepted by whoUepp shall
          only cover up to a maximum of 75% of the principal only of any loan
          request funded by you. Under no circumstance shall you be entitled to
          recover any anticipated or lost interest in respect of a loan request
          funded by you. You understand and accept that the provision and
          validity of insurance cover in respect of a Borrower’s loan request
          funded by you shall be subject to your acceptance of any further terms
          and conditions as whoUepp may stipulate upon your request for
          insurance cover.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          6. Prohibited Activities
        </Text>
        <Text>
          You agree that you will not do the following, in connection with any
          loans, Notes, or other transactions involving or potentially involving
          us: Represent yourself to any person, as a director, officer, employee
          or affiliated person of whouepp; Charge, or attempt to charge, any
          Borrower Member any fee in exchange for your agreement to purchase or
          recommend a Borrower Member’s Member Loan listing, or propose or agree
          to accept any fee, bonus, additional interest, kickback or thing of
          value of any kind, in exchange for your agreement to purchase or
          recommend a Borrower Member’s Member Loan listing; Engage in any
          activities in connection with a Member Loan that require a license as
          a loan broker, credit services organization, credit counselor, credit
          repair organization, lender or other regulated entity, including but
          not limited to soliciting loans or loan applications, quoting loan
          terms and rates and counseling Borrower Members on credit issues or
          loan options; or Violate any applicable federal, state, or local laws.
        </Text>
        <Text fontWeight={"bold"} margin={"20px 0"}>
          7. Access and Use
        </Text>
        <Text>
          Access to and use of the Platform and Website are, at all times,
          subject to our Terms of Use. Secure access to the Platform is provided
          by way of your mobile telephone number and password or biometrics.
          These log-in credentials are unique to you and are the primary method
          of securely identifying you when delivering our Services to you. It is
          therefore extremely important, and it is your responsibility that you
          keep your username and password secure at all times If you suspect
          that your secure access to the Platform has been compromised in any
          way, it is your responsibility to contact us at support@whoUepp.com
          You confirm that you will only use our Platform for the purposes set
          out in these Terms. You confirm that you will not attempt to gain
          unauthorized access to the secure areas of the Website or Platform and
          furthermore you will not attempt to use code or software to manipulate
          or automate functions available on the App. You understand that we may
          store your IP address information and may monitor your use of the App
          in accordance with our cookie policy. Your access to the Platform may
          be restricted at the discretion of whoUepp, including during periods
          of maintenance and updating.
          <Text margin={"20px 0"}>
            We have the right not to act on your instructions including where we
            deem that your instruction was not sufficiently clear, or we could
            not verify your identity to our satisfaction, or the instruction was
            not made by you, or we believe that the instruction may be related
            to an illegal activity. You are responsible for any
            telecommunication costs, broadband fees, or data charges that you
            incur through your access to the Platform.
          </Text>
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          8. The Lending Process
        </Text>
        <Text>
          You acknowledge and confirm that when you offer to lend money through
          the Platform, you will be offering your money to be lent to a Borrower
          through debt financing and you are not lending money to either whouepp
          or any of its directors or employees. We acknowledge that when a
          Borrower applies for a loan, a credit check is run on the Borrower
          through credit bureau, risk scoring, and our proprietary algorithm.
          You acknowledge and confirm that you lend money to a Borrower through
          this Platform when a loan is displayed to you based on your tier and
          lending preferences, and you reserve the right to choose to lend or
          not. You are not permitted to vary or cancel the terms of any Loan
          Agreement to which you are matched as a Lender and have accepted Loan
          Request, and disbursed a Loan to the Borrower. You acknowledge that
          when we engage a debt collection agency on your behalf to recover a
          repayment for a defaulted loan from a borrower, fees for such a
          recovery process shall be at your cost and will be deducted from the
          recovered repayment.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          9. Fees
        </Text>
        <Text>
          Lenders’ Service Charge: This is a portion of the interest charged on
          all loans KYC Verification Fee: This is the fee for obtaining a KYC
          verification on the Platform. The KYC Verification Fee is N2,000. The
          fees and charges in these Terms may be varied from time to time.
          Details of any varied fees and charges shall be communicated to you
          before they take effect.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          10. Term and Termination
        </Text>
        <Text>
          Except as otherwise terminated in accordance with the provisions
          below, these Terms shall commence on the date you register to open an
          account with whoUepp and shall remain valid and binding for as long as
          you have outstanding Loan Agreements. These Terms may be superseded by
          any amended Terms. These Terms may be terminated by you provided that
          you have no outstanding Loan Agreements. Notice of termination must be
          provided in writing to support@whouepp.com Terms may be terminated by
          whouepp at any time without notice.
        </Text>
        <Text fontWeight={"bold"} margin={"20px 0"}>
          11. Tax
        </Text>
        <Text>
          whoUepp does not provide any advice or guidance in relation to
          investment, tax or otherwise and therefore we strongly recommended
          that you consult an independent financial advisor if you are unsure as
          to what your tax obligations are. It is your responsibility to ensure
          that any tax liabilities which you incur (through interest earned on
          loans placed on the Platform or otherwise) are paid to the relevant
          taxation authority. whoUepp will deduct Withholding Tax at the
          applicable rate from any interest income earned by you from loans
          granted to Borrowers and remit it to the relevant government
          authority. Unless otherwise stated herein, neither whoUepp nor any of
          its employees or directors shall have any liability in relation to any
          tax liabilities incurred by you.
        </Text>
        <Text fontWeight={"bold"} margin={"20px 0"}>
          12. Privacy
        </Text>
        <Text>
          You confirm that you have read and understood whoUepp’s Privacy Policy
          which provides information on how we use and store the personal
          information that you provide to us and you further acknowledge that
          whoUepp may amend and update this policy from time to time.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          13. Indemnity
        </Text>
        <Text>
          You agree that neither whoUepp nor its employees or directors have any
          obligation to reimburse or indemnify you for any cost, loss or
          liability which you incur as a result of the failure of any Borrower
          to perform its obligations under a Loan Agreement. You agree to
          indemnify and hold harmless whoUepp, its employees and directors and
          all our other agents or subcontractors, against all liabilities,
          costs, expenses, damages and losses (including any direct, indirect or
          consequential losses, loss of profit, loss of reputation and all
          interest penalties and legal and other reasonable professional costs
          and expenses) suffered or incurred as a result of: your fraudulent or
          illegal use of the Platform, the Services or the Website; your
          negligence or any default by you of any of these Terms or the terms of
          any Loan Agreement to which you are matched; any inaccurate or
          incomplete information that you have knowingly provided to us; your
          allowing any other person to access your account either with your
          permission or as a result of your failure to keep your username and
          password private; any service that you have offered, whether with or
          without our permission, to another third party using our Platform,
          Services;
          <Text margin={"10px 0"}>
            any moratorium, rescheduling, refinancing, suspension of payments or
            similar arrangement or circumstances applying to you and which
            restrict a Borrower’s ability to repay under a Loan Agreement; any
            claim made against you for actual or alleged infringement of
            whouepp’s Intellectual Property Rights or any actual or alleged
            infringement of a third party’s Intellectual Property Rights arising
            out of or in connection with the Services or your use of the
            Platform
          </Text>
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          14. Dispute Resolution
        </Text>
        <Text>
          This Agreement shall be governed by the Laws of the Federal Republic
          of Nigeria. Any dispute arising out of this Agreement, which cannot be
          settled, by mutual agreement/negotiation within 30 days shall be
          referred to arbitration by a single arbitrator at the Lagos Court of
          Arbitration (“LCA”) and governed by the Arbitration and Conciliation
          Act, Cap A10, Laws of the Federal Republic of Nigeria. The arbitrator
          shall be appointed by the Parties, where Parties are unable to agree
          on the choice of an arbitrator, the choice of arbitration shall be
          referred to the LCA. The findings of the arbitrator and subsequent
          award shall be binding on the Parties and may be enforced through a
          Nigerian court of law. Each Party shall bear its cost in connection
          with the Arbitration.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          15. Applicable Law
        </Text>
        <Text>
          This Agreement shall be interpreted and governed by the laws in force
          in the Federal Republic of Nigeria. The Parties submit to Arbitration
          and waive any objections based upon venue.
        </Text>

        <Text fontWeight={"bold"} margin={"20px 0"}>
          16. Contact Us
        </Text>
        <Text>
          If you have any question regarding this Agreement, or comments about
          the App or its contents should be directed to support@whoUepp.com
        </Text>
      </CustomHeader>
    );
};

export default LenderResponsibility;
