import React from "react";
import { Text, List, ListItem } from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";

const PrivacyPolicy = () => {
  return (
    <CustomHeader heading="Our Privacy Policy">
      <Text fontWeight={"bold"}>Effective Date: May 1, 2023</Text>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        1. Introduction
      </Text>
      <Text>
        The whoUepp Peer-to-Peer Application (“whoUepp”, “App”, “Platform” or
        “Application”) is a product developed by Promena Services Limited
        (“Promena” or “Company”) for Whouepp Cooperative Society(“Whouepp”) to
        connect its verified borrowers to its verified lenders with the goals of
        receiving a loan and funding loans respectively.
        <Text as="span" margin={"20px 0"}>
          This Privacy Policy (together with our Terms of Use) explains what
          information we collect, how we collect, share, use and protect your
          personal information when you visit or use the Application, our
          website, and other services offered by Whouepp.{" "}
        </Text>
        <Text as="span">
          Please read this Policy carefully to understand how we collect, use
          and store your personal data. By continuing to use our Application,
          visiting our website, or accessing any of our services, you consent to
          our usage of your personal information as described in this Policy.
          Unless otherwise defined, terms used in this Privacy Policy have the
          same meanings as in our Terms of use.
        </Text>
      </Text>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        2. Information Collection And Use
      </Text>
      <Text>
        We collect several types of information for various purposes to provide
        and improve our Service to you. All information supplied by you as
        defined under the Terms of Use is covered by the provisions of the laws
        of Nigeria and other extant laws and regulations regarding the use and
        management of personal data.
      </Text>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        3. Type Of Data Collected
      </Text>
      <Text>
        Personal Data: While using our Service, we will ask you to provide us
        with certain personally identifiable information that can be used to
        contact or identify you (“Personal Data”). Personally identifiable
        information that we collect includes, but is not limited to, email
        address, BVN, phone number, date of birth, personal identification
        documents, personal financial statements, third parties (e.g. banks,
        clearinghouses, payment service providers, credit bureaus, etc.),
        interaction and communication with our staff, and any communication with
        whouepp. We also collect your phone contact for purpose of allowing you
        to reference your contact list from our App.
      </Text>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        4. Permissions
      </Text>
      <Text>
        We ask for your permission to collect the following data that will help
        us in making decisions on your eligibility for loans:
      </Text>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        5. Cookies And Usage Data
      </Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem>
          ● Usage Data: When you access the Application or our services by or
          through a mobile device, we may collect certain information
          automatically, including, but not limited to, the type of mobile
          device you use, your mobile device unique ID, the IP address of your
          mobile device, your mobile operating system, the type of mobile
          internet browser you use, unique device identifiers and other
          diagnostic data (“Usage Data”).
        </ListItem>
        <ListItem>
          ● Tracking & Cookies Data: We use cookies and similar tracking
          technologies to track the activity on our Service and hold certain
          information. Cookies are files with a small amount of data which may
          include an anonymous unique identifier. Cookies are sent to your
          browser from a website and stored on your device. Tracking
          technologies also used are beacons, tags, and scripts to collect and
          track information and to improve and analyze our Service. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Application.
        </ListItem>
      </List>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        6. Use of Data
      </Text>
      <Text argin={"10px 0"}>
        We will use your collected data for various purposes including:
      </Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>
          ● Establishing your identity and assessing your eligibility for our
          products and services
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Updating and enhancing our records
        </ListItem>
        <ListItem margin={"5px 0"}>● Customer relationship management</ListItem>
        <ListItem margin={"5px 0"}>
          ● Risk identification and management
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Investigation and prevention of illegal and fraudulent activity
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Designing and administering our products and services
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Conducting and improving our business and improving customer service
          experience
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Complying with legal obligations and assisting law enforcement
          agencies
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Identifying and informing you about other products and services that
          we think may be of interest to you, and changes to the Application and
          Service.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To allow you to participate in interactive features of the
          Application or our Services when you choose to do so.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To monitor the usage of the Application and Services
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To detect, prevent, and address technical issues.
        </ListItem>
      </List>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        7. Transfer Of Data
      </Text>
      <Text>
        We may disclose your Personal Data in the good faith belief that such
        action is necessary:
      </Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>
          ● To comply with a legal obligation
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To protect and defend our rights or property
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To prevent or investigate possible wrongdoing in connection with the
          Service
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To protect the personal safety of users of our Services or the
          public
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To protect against legal liability.
        </ListItem>
      </List>

      <Text>
        To the extent that it is necessary and permitted by law, we may reveal
        or share your information with the following people:
      </Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>● Our employees</ListItem>
        <ListItem margin={"5px 0"}>
          ● Our subsidiaries, affiliates, and strategic partners
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Financial services providers including other banks, clearing houses,
          credit bureaus, payment service providers, etc.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Courts, regulators, government agencies, law enforcement agencies,
          etc.
        </ListItem>
      </List>
      <Text fontWeight={"bold"} margin={"20px 0"}>
        8. Disclosure of Data
      </Text>
      <Text>
        Your information, including Personal Data, may be transferred to and
        maintained on computers located outside of your state, province, country
        or other governmental jurisdiction where the data protection laws may
        differ from those in your jurisdiction. If you are located outside
        Nigeria and choose to provide information to us, please note that we
        transfer the data, including Personal Data, to Nigeria and process it
        there. Your consent to this Privacy Policy followed by your submission
        of such information represents your agreement to that transfer. We will
        take all steps reasonably necessary to ensure that your data is treated
        securely and in accordance with this Privacy Policy and no transfer of
        your Personal Data will take place to an organization or a country
        unless there are adequate controls in place including the security of
        your data and other personal information.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        9. Security Of Data
      </Text>
      <Text>
        The security of your data is important to us but remember that no method
        of transmission over the Internet, or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
        Whouepp takes the safeguarding of your personal information very
        seriously. We maintain physical, electronic and procedural safeguards
        that comply with applicable laws and regulations to ensure the security
        of your information, prevention of unauthorized access and use,
        accidental or unlawful alteration and destruction; and other unlawful or
        unauthorized forms of processing. We regularly update our security
        protocols and processes to meet industry standards. If you have been
        provided a password to grant you access to the Application or specific
        areas on our website, you are responsible for keeping this password
        confidential. You must not share your password or other authentication
        details with anyone, Whouepp will not be responsible for any losses you
        may incur if you disclose your password and/or authentication details.
        We will store and retain your data for as long as is necessary for the
        purpose(s) that it was collected. Storage of your data is also
        determined by legal, regulatory, administrative or operational
        requirements
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        10. Service Providers
      </Text>
      <Text>
        We may employ third party companies and individuals to facilitate our
        Service (“Service Providers”), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        11. Links to Other Sites
      </Text>
      <Text>
        Our Services may contain links to other sites that are not operated by
        us. If you click on a third-party link, you will be directed to that
        third party’s site. We strongly advise you to review the privacy policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies, or practices of any
        third-party sites or services.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        12. Children’s Privacy
      </Text>
      <Text>
        Our Services does not address anyone under the age of 18 (“Children”).
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your children have provided us with personal data, please
        contact us. If we become aware that we have collected personal data from
        children without verification of parental consent, we take steps to
        remove that information from our servers
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        13. Your Data Privacy Rights
      </Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>
          ● Right to Rectification: You can modify or change their email,
          password, PIN, and profile picture via your profile.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Right of Access, Right to Erasure, Right to Restrict Processing: You
          can request access or erasure of their personal information, as well
          as request restrictions on further processing of their personal
          information by contacting us at support@whouepp.com. Please allow up
          to 30 days for requests to be processed. We reserve the right to
          charge a reasonable fee to process excessive or repeat requests.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Right to Withdraw Consent: You can stop receiving our promotional
          emails by following the unsubscribe instructions included in every
          email we send, or by adjusting your Marketing Preferences in your
          profile. You also have choices with respect to cookies, as described
          above. By modifying your browser preferences, you have the choice to
          accept all cookies, to be notified when a cookie is set, or to reject
          all cookies. If you choose to reject all cookies some parts of our
          Application and website may not work properly in your case.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Right to lodge a complaint with a supervisory authority: Should you
          feel your data privacy rights are not being adequately protected by
          us, you have the right to lodge a formal complaint with the
          appropriate supervisory authority.
        </ListItem>
      </List>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        14. Changes to This Privacy Policy
      </Text>
      <Text>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service, prior
        to the change becoming effective and update the “effective date” at the
        top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        15. Contact Us
      </Text>
      <Text>
        Contact us if you have questions regarding your data privacy rights or
        would like to submit a related data privacy right request, please email
        us at support@whouepp.com. Please allow up to 30 days for requests to be
        processed. Whouepp reserves the right to charge a reasonable fee to
        process excessive or repeat requests. Contact us if you have any
        questions about this Privacy Policy, please contact us: support@
        whouepp.com.
      </Text>
    </CustomHeader>
  );
};

export default PrivacyPolicy;
