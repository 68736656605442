import React,{useEffect} from "react"
import { Button, Image, Stack, Text } from "@chakra-ui/react";
import borrowIcon from "../assets/borrow.svg";
import lendIcon from "../assets/lend.svg";
import PaddingContainer from "./PaddingContainer";
import dash from "../assets/dash.svg";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { GetStartedModal } from "./GetStartedModal";

function Features() {

  useEffect(() => {
    AOS.init({duration: 2000})
}, [])
  return (
    <PaddingContainer
      bg="#d9ece93d"
      id="feature"
      position={"relative"}
      data-aos="zoom-in"
    >
      <Stack
        direction={["column", "column", "row"]}
        alignItems={["center", "center", "flex-start"]}
        fontFamily={"Trenda-regular"}
        spacing="40px"
        zIndex={"99"}
      >
        <Stack lineHeight={"2"} flex="1">
          <Stack position={"relative"}>
            <Text
              position={"absolute"}
              fontFamily={"Montserrat"}
              fontWeight="800"
              color="#3a49d129"
              left="120px"
              top="-15px"
              fontSize="2em"
            >
              System
            </Text>
            <Text
              fontFamily={"Montserrat"}
              fontWeight="800"
              color="#1A0031"
              fontSize={"1.9em"}
            >
              {" "}
              <Text
                as="span"
                style={{ color: "#42A293", fontWeight: "bolder" }}
                display={["none", "none", "none", "block"]}
              >
                &mdash;{" "}
              </Text>
              Features
            </Text>
          </Stack>

          <Text
            fontFamily={"Trenda-regular"}
            maxW={["400px", "400px", "300px"]}
            mb="20px !important"
          >
            At our peer-to-peer lending platform, we ensure that all
            transactions are secure and private.
          </Text>
          <Stack direction={"row"} alignItems="center">
            <GetStartedModal />
          </Stack>
        </Stack>

        <Stack position={"relative"} direction={["column", "column", "row"]}>
          <Image
            display={["none", "none", "none", "block"]}
            src={dash}
            alt="dash"
            position={"absolute"}
            left="5px"
            top="-30px"
            minW={"700px"}
          />
          <Stack
            direction={["column", "column", "row"]}
            spacing={"50px"}
            zIndex="99"
          >
            <FeaatureCard
              icon={borrowIcon}
              title={"  Borrowing"}
              subtitle="  (Apply for a quick loan to meet needs)"
              subColor="#3A49D1"
              desc={` Our platform allows borrowers to access funds with competitive
          interest rates and flexible repayment terms.`}
            />
            <FeaatureCard
              icon={lendIcon}
              title={"Lending"}
              subColor="#42A293"
              subtitle="(Apply for a quick loan to meet needs)"
              desc={`Lenders, we provide a secure platform with various loan products and
          competitive returns.`}
            />
          </Stack>
        </Stack>
      </Stack>
    </PaddingContainer>
  );
}

export default Features;

export const BoxShadow = ({ children }) => {
  return (
    <Stack
      p="30px 20px"
      borderRadius={"9px"}
      maxW={["400px", "400px", "280px"]}
      boxShadow={"2px 2px 6px #0000001c"}
      bg="#fff"
    >
      {children}
    </Stack>
  );
};
export const FeaatureCard = ({ icon, title, subtitle, subColor, desc }) => {
  return (
    <BoxShadow>
      <Stack direction={"row"} alignItems="center" spacing={"20px"} mb="20px ">
        <Image loading="lazy" w="30px" src={icon} alt="borrow" />
        <Text
          fontFamily={"Montserrat"}
          fontWeight="600"
          color="#0A0013"
          fontSize={"1.2em"}
        >
          {title}
        </Text>
      </Stack>
      <Text fontSize={".8em"} fontWeight="600" color={subColor}>
        {subtitle}
      </Text>
      <Text lineHeight="1.7" mt="20px !important">
        {desc}
      </Text>
    </BoxShadow>
  );
};
