import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import googlePlay from "../../assets/google-play.svg";
import appStore from "../../assets/apple.svg";

function LenderHeroSection({ img, description, title, direction, }) {
  return (
    <Box w="100%">
      <Flex
        px="20px"
        // py="20px"
        width="100%"
        direction={direction}
        alignItems={"center"}
        justify={["space-between"]}
        p={["0 30px", "20px 30px", "40px 30px", "40px 80px"]}
      >
        <Stack width={["100%", "100%", "48%"]} maxW="500px">
          <Text
            fontSize={"2em"}
            fontFamily="Montserrat"
            fontWeight={"300"}
            color="#2D2C2C"
            mb="20px"
          >
            <span style={{ fontWeight: "300" }}>{title?.[0]}</span>
            <br />
            <span style={{ color: "#180656", fontWeight: "800" }}>
              {title?.[1]}
            </span>{" "}
            <span style={{ color: "#180656", fontWeight: "800" }}>
              {title?.[2]}
            </span>{" "}
            {title?.[3]}
          </Text>

          <Text
            fontFamily={"Trenda-regular"}
            fontSize={["19px"]}
            color="#434242"
            lineHeight={"1.7"}
            maxW="470px"
            mb="20px !important"
          >
            <Box color="#434242">{description}</Box>
          </Text>

          <Stack direction={"row"} alignItems="center" spacing="20px">
            <AppIcon
              borderC={"#0A0013"}
              img={googlePlay}
              subTitle="Download on"
              title="Google Play"
            />
            <AppIcon
              borderC={"#42A293"}
              img={appStore}
              subTitle="Download on the"
              title="App Store"
            />
          </Stack>
        </Stack>
        <Box width={["100%", "100%", "48%"]}>
          <Image
            loading="lazy"
            h="400px"
            src={img}
            alt="hero-img"
            mt={["20px", "30px", 0]}
            // position={"absolute"}
          />
        </Box>
      </Flex>
    </Box>

    // </Stack>
  );
}

export default LenderHeroSection;

export const AppIcon = ({ borderC, img, subTitle, title }) => {
  return (
    <Stack
      direction={"row"}
      alignItems="center"
      borderRadius={"7px"}
      border={`1px solid ${borderC}`}
      p="5px 25px"
      spacing={"10px"}
      h="60px"
    >
      <Image w="15px" src={img} alt="app-logo" />
      <Stack spacing={"-1"}>
        <Text fontSize={".5em"} color="#0A0013">
          {subTitle}
        </Text>
        <Text fontSize={".9em"} color="#0A0013" fontFamily={"Trenda-bold"}>
          {title}
        </Text>
      </Stack>
    </Stack>
  );
};
