import React from "react";
import { Text, List, ListItem } from "@chakra-ui/react";
import CustomHeader from "../components/CustomHeader";

const Terms = () => {
  return (
    <CustomHeader heading="Terms and Conditions">
      <Text fontWeight={"bold"} margin={"20px 0"}>
        1. General
      </Text>
      <Text>
        Welcome to our Terms and Conditions of Use. These terms affect your
        legal rights, endeavor to read them and treat them with the utmost
        importance.
      </Text>
      <Text>
        The whoUepp Peer-to-Peer Application (“whoUepp”, “App”, “Platform” or
        “Application”) is a product developed by Promena Services Limited
        (“Promena” or “Company”) for the Whouepp Cooperative Society
        (“Whouepp”). These terms and conditions (“Terms” or “Agreement”) is an
        agreement between Whouepp ( “us”, “we” or “our”) and you (“User”, “you”
        or “your”). "Terms" means these terms, consisting of these terms of use;
        and any other relevant specific terms, policies, disclaimers, rules and
        notices agreed between the parties, (including any that may be
        applicable to a specific section or module of this application);
      </Text>
      <Text>
        Also, registering to use whoUepp shall be deemed as an agreement as a
        user, to be a member of the Whouepp Cooperative Society, and as such,
        you agree to the rules and by-laws binding the cooperative society.
      </Text>

      <List padding={["0 10px", "0 30px"]}>
        <ListItem>
          ● User or You means any person who uses whoUepp. User means a person
          that has signed up on the whoUepp platform and holds a client account,
          to make use of the services rendered by whoUepp.
        </ListItem>
        <ListItem>
          ● “Lender” means an organization or person that lends money to the
          “borrower”
        </ListItem>
        <ListItem>
          ● "Borrower" means a person or organization that takes out a “credit”
          from a “lender” under an agreement to pay it back later, typically
          with interest.
        </ListItem>
        <ListItem>
          ● “credit” means the amount of money requested by the user
        </ListItem>
        <ListItem>
          ● “Disbursement Date” means the date the Lender repays the loan to the
          Borrower
        </ListItem>
        <ListItem>
          ● “Application” means the whoUepp Peer-to-Peer Application.
        </ListItem>
        <ListItem>
          ● “PIN” means personal identification number being the secret code you
          choose for secure use of (and access to) your whoUepp account.
        </ListItem>
      </List>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        2. Accounts
      </Text>
      <Text argin={"10px 0"}>
        You must have an account with Whouepp to be able to use the application.
        The Application can be installed on a mobile device running an operating
        system supported by Whouepp. To log onto the application, you will need
        to enter your User ID and other security information that we request, or
        where available, by using biometric authentication. We may notify you
        from time to time about changes in the security information. We will
        automatically log you out of the Application, if you have not used it
        for 15 minutes. If you create an account in the Application, you are
        responsible for maintaining the security of your account and you are
        fully responsible for all activities that occur under the account, and
        any other actions taken in connection with it. Providing false contact
        information of any kind may result in the termination of your account.
        You must immediately notify us of any unauthorized uses of your account
        or any other breaches of security. We will not be liable for any acts or
        omissions by you, including any damages of any kind incurred as a result
        of such acts or omissions.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        3. Qualification for Use
      </Text>
      <Text>
        Without prejudice to any other specific requirement which may be laid
        out in these Terms, your use of whoUepp and specifically, your
        acceptance of these Terms as aforesaid shall be deemed to be a
        representation from you that you are above 18 years of age and are fully
        able and competent to enter into the terms, conditions, obligations,
        affirmations, representations, and warranties set forth in these Terms,
        and to abide by and comply with these Terms. Whouepp grants each user a
        limited, revocable license to use this Application, subject to the
        Terms. Any person wishing to use this application contrary to the Terms
        must obtain prior written consent from Promena. The information offered
        on this application is for the user’s guidance only.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        4. Links to Other Peer-To-Peer Lending Applications
      </Text>
      <Text>
        Although this Peer-to-Peer Lending Application may be linked to other
        software applications, we are not, directly or indirectly, implying any
        approval, association, sponsorship, endorsement, or affiliation with any
        linked software application, unless specifically stated herein. We are
        not responsible for examining or evaluating, and we do not warrant the
        offerings of, any businesses or individuals or the content of their
        software applications. We do not assume any responsibility or liability
        for the actions, products, services, and content of any other third
        parties. You should carefully review the legal statements and other
        conditions of use of any software application which you access through a
        link from this Application. Your linking to any other off-site software
        application is at your own risk.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        5. Intellectual Property Rights
      </Text>
      <Text>
        This Agreement does not transfer to you any intellectual property owned
        by Promena or third parties, and all rights, titles, and interests in
        and to such property will remain (as between the parties) solely with
        the Company. All trademarks, service marks, graphics, and logos used in
        connection with our Application and Services are trademarks or
        registered trademarks of Promena. Other trademarks, service marks,
        graphics, and logos used in connection with our Application or Services
        may be the trademarks of other third parties. Your use of our
        Application and Services grants you no right or license to reproduce or
        otherwise use the Company or third-party trademarks.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        6. Copyright Infringement
      </Text>
      <Text>
        All rights reserved. No part of the Application may be copied,
        reproduced, ripped, recorded, re-engineered, decompiled, disassembled,
        modified, reproduced, distributed, or transmitted in any form or by any
        means either electronic or mechanical methods, without the prior written
        permission of Promena or Whouepp.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        7. Technology Limitations and Modifications
      </Text>
      <Text>
        Promena will make reasonable efforts to keep the Application
        operational. However, certain technical difficulties or maintenance may,
        from time to time, result in temporary interruptions. Promena reserves
        the right, periodically and at any time, to modify or discontinue,
        temporarily or permanently, functions and features of the Application
        with or without notice, without liability to you for any interruption,
        modification, or discontinuation of the Application, or Services or any
        function or feature thereof. You understand and agree that we have no
        obligation to maintain, support, upgrade, or update the Application, or
        to provide all or any specific content through the Application.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        8. Limitation Of Liability
      </Text>
      <Text>
        To the fullest extent permitted by applicable law, in no event will
        Promena, its affiliates, officers, directors, employees, agents,
        suppliers or licensors be liable to any person for (a): any indirect,
        incidental, special, punitive, cover or consequential damages
        (including, without limitation, damages for lost profits, revenue,
        sales, goodwill, use or content, impact on business, business
        interruption, loss of anticipated savings, loss of business opportunity)
        however caused, under any theory of liability, including, without
        limitation, contract, tort, warranty, breach of statutory duty,
        negligence or otherwise, even if Promena has been advised as to the
        possibility of such damages or could have foreseen such damages.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        9. Disclaimer Warranty
      </Text>
      <Text>
        Our Application and services are provided on an “as is” and “as
        available” basis without warranties of any kind, either expressed or
        implied, including, but not limited to, the implied warranty of
        merchantability, fitness for a particular purpose and non-infringement.
        No warranty is provided that the Application will be free from defects
        or viruses or operation of the Application will be uninterrupted. Your
        use of the Application and any other material or services downloaded or
        made available to you through the software is at your discretion and
        risk, and you are solely responsible for any damage resulting from their
        use.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        10. Prohibited Uses
      </Text>
      <Text>
        In addition to other terms, as outlined in the Agreement, you are
        prohibited from using the Peer-to-Peer Application or its Content:
      </Text>

      <Text>For any unlawful purpose;</Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>
          ● To solicit others to perform or participate in any unlawful acts;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To violate any international, federal or state regulations, rules,
          laws, or local ordinances;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To infringe upon or violate our intellectual property rights or the
          intellectual property rights of others;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To harass, abuse, insult, harm, defame, slander, disparage,
          intimidate, or discriminate based on gender, sexual orientation,
          religion, ethnicity, race, age, national origin, or disability;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To submit false or misleading information;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or any related Application, other
          Application or the internet;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To collect or track the personal information of others;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To spam, phish, pharm, pretext, spider, crawl, or scrape;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● For any obscene or immoral purpose;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● To interfere with or circumvent the security features of the
          Application or any related application, other peer-to-peer lending
          applications, or the internet.
        </ListItem>
      </List>
      <Text>
        We reserve the right to terminate your use of the Application, our
        Services or any related software application for violating any
        prohibited uses.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        11. Restrictions
      </Text>
      <Text>You shall not:</Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>
          ● Modify, revise or create any derivative works of the Application
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Decompile, reverse engineer or otherwise attempt to derive the
          source code for the Application;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Redistribute, sell, rent, lease, sublicense, or otherwise transfer
          rights to the Application; or
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Remove or alter any proprietary notices, legends, symbols or labels
          in the Application including, but not limited to, any trademark, logo,
          or copyright.
        </ListItem>
      </List>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        12. Indemnification
      </Text>
      <Text>
        You agree to indemnify and hold Whouepp and its affiliates, directors,
        officers, employees, and agents harmless from and against any
        liabilities, losses, damages or costs, including reasonable attorneys’
        fees, incurred in connection with or arising from any third-party
        allegations, claims, actions, disputes, or demands asserted against any
        of them as a result of or relating to your Content, your use of the
        Application or our Services or any willful misconduct on your part.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        13. Severability
      </Text>
      <Text>
        All rights and restrictions contained in these Terms and Conditions of
        Use may be exercised and shall apply and binding only to the extent that
        they do not violate any applicable laws and are intended to be limited
        to the extent necessary so that they will not render this Terms of Use
        illegal, invalid or unenforceable. If any provision or portion of any
        provision of this Agreement shall be held to be illegal, invalid or
        unenforceable by a court of competent jurisdiction, it is the intention
        of the parties that the remaining provisions or portions thereof shall
        constitute their agreement concerning the subject matter hereof, and all
        such remaining provisions or portions thereof shall remain in full force
        and effect.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        14. Dispute Resolution
      </Text>
      <Text>
        The validity, construction, and performance of these Terms of Use shall
        be governed by the laws of the Federal Republic of Nigeria. Parties
        shall use their best endeavours to amicably settle any dispute or
        difference of opinion arising from or in connection with this Terms of
        Use through mutual discussions. Where the Parties are unable to resolve
        the dispute through mutual discussions, the dispute or difference of
        opinion shall be referred to mediation conducted by their legal
        representatives or financial auditors, where financial matters are
        involved. Failing which the dispute shall finally be referred to
        arbitration in accordance with the Arbitration and Conciliation Act Cap.
        A18, Laws of the Federation of Nigeria 2004, or any amendment thereto.
        The place of arbitration shall be Lagos State, Nigeria and there shall
        be a sole arbitrator who shall be appointed jointly by the parties.
        Where the parties fail to jointly agree on the appointment, they shall
        approach the Lagos Multi-Door Courthouse to appoint a sole arbitrator
        who shall be competent to adjudicate on the issue in dispute. The
        language to be used in the arbitral proceedings shall be English. The
        arbitral award shall be final and binding between the Parties. The
        reference of any dispute to arbitration shall not prevent the Parties
        from seeking any form of protective reliefs/injunctions/specific
        performance from a court of law via motion or other originating
        processes for the protection of any res pending the constitution of the
        Arbitral Panel and/or final award.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        15. Chargebacks
      </Text>
      <Text>You may be assessed Chargebacks for</Text>
      <List padding={["0 10px", "0 30px"]}>
        <ListItem margin={"5px 0"}>● customer disputes;</ListItem>
        <ListItem margin={"5px 0"}>
          ● unauthorized or improperly authorized transactions;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● transactions that do not comply with the terms of this Agreement or
          are allegedly unlawful or suspicious;
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● or any reversals for any reason by our processor, or the acquiring
          or issuing banks.
        </ListItem>
        <ListItem margin={"5px 0"}>
          ● Where a chargeback occurs, you are immediately liable for all
          claims, expenses, fines, and liability we incur arising out of that
          chargeback and agree that we may recover these amounts by debiting
          your Bank Account. Where these amounts are not recoverable through
          your bank account, you agree to pay all such amounts through any other
          means
        </ListItem>
      </List>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        16. Changes and Amendments
      </Text>
      <Text>
        We reserve the right to modify this Terms of Use or its policies
        relating to the Application or our Services at any time, effective upon
        posting an updated version of this Terms of Use in the Application. When
        we do, we will send you an email to notify you. Continued use of the
        Application after any such changes shall constitute your consent to such
        changes.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        17. Acceptance Of These Terms
      </Text>
      <Text>
        Downloading and installing whoUepp shall be deemed to constitute
        sufficient proof that you have read, understood, and accepted these
        Terms. If you do not agree to these Terms you must not proceed to
        download whoUepp. You acknowledge that you have read this Agreement and
        agree to all its terms and conditions. By using the Application or its
        Services you agree to be bound by these Terms of Use. If you do not
        agree to abide by the terms of this Terms of Use, you are not authorized
        to use or access the Peer-to-Peer Application and its Services.
      </Text>

      <Text fontWeight={"bold"} margin={"20px 0"}>
        18. Contacting Us
      </Text>
      <Text>
        If you have any questions about this Agreement, please contact us: at
        support@whouepp.com.
      </Text>
    </CustomHeader>
  );
};

export default Terms;
